import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import LayoutPdf from '@/components/template/LayoutPDF.vue';
import ReexamePDF from '@/modulo/reexame/components/ReexamePDF.vue';

const DadosView = () => import(/* webpackChunkName: 'abertura' */ "../views/perfil/DadosView.vue");
const ContatosView = () => import(/* webpackChunkName: 'abertura' */ "../views/perfil/ContatosView.vue");
const FinalView = () => import(/* webpackChunkName: 'abertura' */ '@/views/perfil/FinalView.vue');

//const Renovacao = () => import(/* webpackChunkName: 'servicos' */'@/views/renovacao/Renovacao.vue');
const Emissao = () => import(/* webpackChunkName: 'servicos' */ '@/views/emissaoLadv/Emissao.vue');
const Consulta = () => import(/* webpackChunkName: 'servicos' */ '@/views/consultas/Consulta.vue');
const ConsultaRelatorio = () => import(/* webpackChunkName: 'servicos' */ '@/views/consultas/ConsultaRelatorio.vue');
const AdicaoMudancaView = () => import(/* webpackChunkName: 'servicos' */ '@/modulo/adicaoMudanca/AdicaoMudancaView.vue');
const ReexameView = () => import(/* webpackChunkName: 'servicos' */ '@/modulo/reexame/ReexameView.vue');
const RenovacaoBoletoPDF = () => import(/* webpackChunkName: "layout-pdf" */ '@/components/template/RenovacaoBoletoPDF.vue');
const AdicaoMudancaPDF = () => import(/* webpackChunkName: "adicao-boleto-pdf" */ '@/modulo/adicaoMudanca/components/AdicaoMudancaPDF.vue');
const LaudoLADVView = () => import(/* chunckName: 'reexame-boleto-pdf' */ '@/components/template/LaudoLADV.vue');

import TokenInvalido from '@/views/estatico/TokenInvalido.vue';
import Login from '@/views/login/Login.vue';
import GetToken from '@/services/GetToken';
import Constants from "@/enums/constants";

const routerBeforeEnter = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => { 
    !from.name ? next({ name: 'servico' }) : next(); 
};

const routerBeforeEnterStorage = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    localStorage.getItem("valido") != "1" ? next({ name: 'servico' }) : next();
};

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "login",
        component: Login,
        meta: {
            requiredAuth: false
        }
       
    },
    {
        path: "/token-invalido",
        name: "tokenInvalido",
        component: TokenInvalido,
        meta: {
            requiredAuth: false
        },
       
    },
    {
        path: "/servico",
        name: "servico",
        component: Home,
        meta:{
            index: 0,
            requiredAuth: true,
            title: 'Pilotar Serviços'
        }
    },
    {
        path: '/:cnpj/:token',
        name: 'homeComParametros',
        component: Home,
        meta: {
            requiredAuth: false
        },
        beforeEnter:(to, _, next) => {
            const { token, cnpj } = to.params;
            cnpj && localStorage.setItem(Constants.CHAVE_CNPJ, cnpj.toString());
            GetToken.getToken((`${token}`)).then(() => {
                next({ name: 'servico' });
            }).catch(() => {
                next({ name: 'tokenInvalido' });
            });
        }
    },
    {
        path: "/dados",
        name: "dados",
        component: DadosView,
        meta:{
            index: 1,
            requiredAuth: true,
            title: 'Abertura de RENACH'
            
        },
        beforeEnter: routerBeforeEnter
    },
    {
        path: "/contatos",
        name: "contatos",
        component: ContatosView,
        meta:{
            index: 2,
            requiredAuth: true,
            title: 'Abertura de RENACH'
        },
        beforeEnter: routerBeforeEnter
    },
    {
        path: "/final",
        name: "final",
        component: FinalView,
        meta:{
            index: 3,
            requiredAuth: true,
            title: 'Abertura de RENACH'
        },
        beforeEnter: routerBeforeEnter
    },
    // {
    //     path: "/renovacao",
    //     name: "renovacao",
    //     component: Renovacao,
    //     meta:{
    //         index: 4,
    //         requiredAuth: true,
    //         title: 'Renovação CNH'
    //     },
    //     beforeEnter: routerBeforeEnter
    // },
    {
        path: "/emissao",
        name: "emissao",
        component: Emissao,
        meta:{
            index: 5,
            requiredAuth: true,
            title: 'Emissão LADV'
        },
        beforeEnter: routerBeforeEnter
    },
    {
        path: "/consulta",
        name: "consulta",
        component: Consulta,
        meta:{
            index: 6,
            requiredAuth: true,
            title: 'Consulta LADV e RENACH'
        },
        beforeEnter: routerBeforeEnter
    },
    {
        path: "/relatorio",
        name: "relatorio",
        component: ConsultaRelatorio,
        meta:{
            index: 7,
            requiredAuth: true,
            title: 'Relatórios'
        },
        beforeEnter: routerBeforeEnter
    },
    {   
        path: "/boleto",
        name: "boleto",
        component: LayoutPdf,
        meta:{
            title: 'Primeira Habilitação',
            requiredAuth: true
        },
        beforeEnter: routerBeforeEnterStorage
    },
    {   
        path: "/laudo-ladv",
        name: "ladv",
        component: LaudoLADVView,
        meta:{
            title: 'Laudo LADV',
            requiredAuth: true
        },
        beforeEnter: routerBeforeEnterStorage
    },
    {   
        path: "/boleto-renovacao",
        name: "boletoRenovacao",
        component: RenovacaoBoletoPDF,
        
        meta:{
            title: 'Renovação CNH',
            requiredAuth: true
        },
        beforeEnter: routerBeforeEnterStorage
    },
    {
        path: "/adicao-mudanca",
        name: "adicao",
        component: AdicaoMudancaView,
        meta:{
            index: 6,
            requiredAuth: true,
            title: 'Mudança'
        },
        beforeEnter: routerBeforeEnter
    },
    {
        path: "/reexame",
        name: "reexame",
        component: ReexameView,
        meta:{
            index: 7,
            requiredAuth: true,
            title: 'Reexame'
        },
        beforeEnter: routerBeforeEnter
    },
    {   
        path: "/adicao-mudanca-boleto",
        name: "boletoMudanca",
        component: AdicaoMudancaPDF,
        meta:{
            title: 'Adição ou Mudança PDF',
            requiredAuth: true
        },
        beforeEnter: routerBeforeEnterStorage
    },
    {   
        path: "/reexame-boleto",
        name: "boletoReexame",
        component: ReexamePDF,
        meta:{
            title: 'Reexame',
            requiredAuth: true
        },
        beforeEnter: routerBeforeEnterStorage
    },
    {
        path: '/:catchAll(.*)',
        redirect: { name: 'login' },
        meta: {
            notFound: true,
            requiredAuth: false
        },
    },
   
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// router.beforeEach((to, _, next) => {
//     const token = localStorage.getItem(Constants.CHAVE_TOKEN);
//     const requiredAuth = to.matched.some(authRec => authRec.meta.requiredAuth);  
//     if(requiredAuth && token !== null) next();
   
// });


// Setar título na aba do browser
const DEFAULT_TITLE = "Pilotar";

router.afterEach((to) => {
    document.title = <string> to.meta.title || DEFAULT_TITLE;
});

export default router;