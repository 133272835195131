export const logoPilotar = require('./logo-pilotar.svg');
export const groupVectorTop = require('./group-vector.png');
export const groupIphone = require('./cell-phones.png');
export const iphone = require('./iPhone.png');
export const simulatorVirtual = require('./iPhone-simulator.png'); 
export const groupVector = require('./group-vector.png');
export const login = require('./login-pg.svg'); 
export const token = require('./token-invalido.svg'); 
export const diretorGeral = require('./assinatura-diretor-geral.png');
export const imprimir = require('./imprimir.svg');
export const calendario = require('./calendario.svg');
// export const logoDetranBA = require('./logo-detran-ba.png');
export const logoDetranBA = require('./logo-detran-ba.png');
export const imageTesoura = require('./tesoura128.png');
