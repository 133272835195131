<template >
    <teleport to="body">
        <div v-if="vmModal.ativarModal" @keydown.enter.stop 
             class="fixed top-0 left-0 flex items-center justify-center w-full h-full z-50 bg-black bg-opacity-50" >
            <div class="fixed mx-10" @click.stop >
                <div class="flex flex-col overflow-hidden bg-white rounded-2xl">
                    <div class="flex flex-col bg-white" >
                        <component :is="vmModal.component">
                        </component>
                    </div>
                </div>
            </div>
        </div>
    </teleport>   
</template>
<script lang='ts'>
import { defineAsyncComponent, defineComponent } from 'vue';
import vmModal from '@/ViewModel/ModalViewModel';

const Acesso = defineAsyncComponent({ 
    loader: () => import('@/components/modais/Acesso.vue'),
});

const ModalFactory = defineComponent({
    name: 'ModalFactory',
    components: {
        Acesso
    },

    setup() {
        return { 
            vmModal
        };
    }
});

export default  ModalFactory ;
</script>