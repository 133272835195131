<template>
    <PerfilMainView >
        <div class="rounded-lg sm:rounded-lg px-6 py-10 bg-white w-9/12">

            <header class="flex justify-center mb-4">
                <div class="mb-4 sm:mb-0 items-center">
                    <img  :src="logoPilotar" alt="Pilotar">
                </div>
            </header>

            <div class="flex flex-col items-center" >
                <img  class="mt-10"  :src="token" alt="Pilotar">
                
                <div class="">
                    <h1 class="text-lg text-center mt-10">
                        Não é possível acessar essa página para 
                        solicitar os serviços de abertura de renach e/ou 
                        renovação de cnh e/ou emissão de LADV, pois
                        o tempo expirou. Por gentileza, retorne ao
                        SuperPrático e tente novamente.
                    </h1>
                </div>   
               
                <div class=" w-11/12">  
                    <a href="https://admin-ba.superprati.co/"> 
                        <Button color="custom" 
                                class="h-12  text-white mt-5">
                            Acessar o SuperPrático
                        </Button>
                    </a>
                </div>   

            </div>   
        </div>
        
    </PerfilMainView>
</template>
<script lang='ts'>
import { defineComponent } from 'vue';

import { Button } from '@/components';
import { logoPilotar, token } from '@/assets/images';

import PerfilMainView from '@/views/perfil/PerfilMainView.vue';

const TokenInvalido = defineComponent({
    name: 'TokenInvalido',
    components: {
        Button,
        PerfilMainView
        
    },

    setup () {



        return {
            logoPilotar,
            token          
        };
    }
});

export default TokenInvalido;
</script>


