<template>
    <div class="bg-white flex items-center h-full text-neutral font-semibold px-5 pt-1 rounded">
        <svg class="animate-spin h-10 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
        </svg>
        {{ message }}
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Loading',
    props: {
        message: {
            type: String,
            defaul: 'Carregando...'
        }
    } 
});
</script>