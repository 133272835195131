import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full my-2" }
const _hoisted_2 = { class: "w-full relative" }
const _hoisted_3 = ["for"]
const _hoisted_4 = ["name", "id", "disabled"]
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "truncate" }
const _hoisted_7 = {
  key: 0,
  class: "text-red-500 text-xs pt-1 text-left"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", {
        for: _ctx.name,
        class: _normalizeClass(["origin-left absolute -top-5 sm:-top-6 text-sm truncate", 'text-neutral-900'])
      }, _toDisplayString(_ctx.$attrs['placeholder']), 9, _hoisted_3),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        onFocus: _cache[1] || (_cache[1] = ($event: any) => {_ctx.$emit('focus', $event); _ctx.inFocus = true}),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => {_ctx.$emit('blur', $event); _ctx.inFocus = false}),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.inFocus = true)),
        onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.inFocus = true)),
        name: _ctx.name,
        id: _ctx.name,
        disabled: _ctx.disabled,
        class: _normalizeClass(["w-full outline-none px-4 border rounded-md transition-all duration-200 ease-in-out transform focus:border-primary font-sans text-sm text-neutral-900 flex items-center bg-transparent h-11", [{ 'border-red-500 border': _ctx.error.length > 0 }, {'background-color': _ctx.placeholderBackgroundColor }]])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, index) => {
          return (_openBlock(), _createElementBlock("option", {
            key: index,
            value: item.name,
            class: "overflow-y-auto"
          }, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(item.value), 1)
          ], 8, _hoisted_5))
        }), 128))
      ], 42, _hoisted_4), [
        [_vModelSelect, _ctx.inputValue]
      ])
    ]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}