<template>
    <Teleport to="body">
        <transition name="fade">
            <div class="fixed top-0 right-0 w-full h-screen z-50 bg-black bg-opacity-40 flex items-center justify-center" v-if="isOpened" @click.self="$emit('update:isOpened', false)">
                <transition name="modalAppear" appear>
                    <div class="bg-white rounded-xl text-center w-80 z-30" style="max-width: 90%" >
                        <slot/>
                    </div>
                </transition>
            </div>
        </transition>
    </Teleport>
</template>

<script lang='ts'>
import { defineComponent, PropType } from 'vue';

const Modal = defineComponent({
    props: {
        isOpened: {
            type: Boolean,
            default: false
        },
        size: {
            type: String as PropType<'small' | 'medium' |'large'>,
            default: 'small'
        }
    }
});

export default Modal;
</script>

<style>

</style>