import axios, {  AxiosResponse } from 'axios';
import http from '@/plugins/http';
import constants from "@/enums/constants";
const baseUrl = process.env.VUE_APP_API_PREFIX + process.env.VUE_APP_API_AUTH;

export default class GetToken {
   
    public static getToken(tokenReq: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let token = '';
            axios.get(`${baseUrl}api/auth/validar?token=` + tokenReq)
                .then((res: AxiosResponse) => {
                    token = res.data.token;
                    resolve(token);
                    localStorage.setItem(constants.CHAVE_TOKEN, token);
                    // setar token no authorization do plugin http
                    http.defaults.headers = {
                        'Authorization' : `Bearer ${token}`
                    };
                }).catch((e) => { 
                    reject(e);
                    console.error( "token error: " + e);
                });
        });
       
    }
    
}