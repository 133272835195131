import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "bg-white rounded-xl text-center w-80 z-30",
  style: {"max-width":"90%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isOpened)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "fixed top-0 right-0 w-full h-screen z-50 bg-black bg-opacity-40 flex items-center justify-center",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('update:isOpened', false)), ["self"]))
            }, [
              _createVNode(_Transition, {
                name: "modalAppear",
                appear: ""
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _renderSlot(_ctx.$slots, "default")
                  ])
                ]),
                _: 3
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}