import { format, formatISO, isValid, parse } from 'date-fns';
import parseISO from 'date-fns/parseISO';

export const dateFormatInput = (value: string): string => {
    if (typeof value !== 'string' || value.length <= 0) return '';

    // Converter data string em objeto data.
    const dateObj = parseISO(value);
    let dateFormat: string = '';
    
    // Validar data.
    if (isValid(dateObj)) {

        // Formatar data no padrão 00/00/0000.
        dateFormat = format(dateObj, "dd/MM/yyyy");
    }
    
    return `${dateFormat}`;
};


// formatar data para o padrão ISO8601
export const formatDateToISO = (value: string): string => {

    if (value.length < 10)  return '';
        
    // inverter data 10/10/2020 para 2020/10/10
    const invertDate = value.split('/').reverse().join('-');
    
    // transformar data para o padrão ISO8601
    const dateIso = parse(invertDate, 'yyyy-MM-dd', new Date());

    let dateFormattedISO: string = ''; 

    // Validar data.
    if (isValid(dateIso)) {
        dateFormattedISO = formatISO(dateIso);
    }
      
    return `${dateFormattedISO}`;
};