<template >
    <div class="shadow-md rounded-md sm:rounded-lg px-11  bg-white w-full h-full sm:w-card  " >
        <slot name="header"> 
        </slot>  
        <slot>
        </slot>  
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { logoPilotar } from '@/assets/images';

const Card = defineComponent({
    name: 'Card',
    components: {
       
    },

    setup () {



        return { 
            
            logoPilotar
           
        };
    }
});

export default  Card ;
</script>