import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full my-10 sm:my-2" }
const _hoisted_2 = { class: "w-full relative" }
const _hoisted_3 = ["for"]
const _hoisted_4 = ["type", "name", "id", "placeholder", "disabled"]
const _hoisted_5 = {
  key: 0,
  class: "text-red-500 text-xs pt-1 text-left"
}
const _hoisted_6 = {
  key: 1,
  class: "text-red-500 text-xs pt-1 text-left"
}
const _hoisted_7 = {
  key: 2,
  class: "text-red-500 text-xs pt-1 text-left"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", {
        for: _ctx.name,
        class: _normalizeClass(["origin-left absolute -top-5 sm:-top-6 text-sm text-neutral-900", [_ctx.error.length > 0 ? 'text-red-500' : 'text-neutral-900 truncate' ]])
      }, _toDisplayString(_ctx.$attrs['placeholder']), 11, _hoisted_3),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        type: _ctx.type === 'password' ? _ctx.passwordType : _ctx.type ,
        onFocus: _cache[1] || (_cache[1] = ($event: any) => {_ctx.$emit('focus', $event); _ctx.inFocus = true}),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => {_ctx.$emit('blur', $event); _ctx.inFocus = false}),
        name: _ctx.name,
        id: _ctx.name,
        placeholder: _ctx.active ? '' : _ctx.hintText,
        disabled: _ctx.disabled,
        autocomplete: "off",
        class: _normalizeClass(["w-full outline-none px-4 border rounded-md transition-all duration-200 ease-in-out transform focus:border-primary font-sans text-sm text-neutral-900 flex items-center bg-transparent h-11", [{ 'border-red-500 border': _ctx.error.length > 0 || _ctx.campoDisabledError || _ctx.erroCep }, {'background-color': _ctx.placeholderBackgroundColor }]])
      }, null, 42, _hoisted_4), [
        [_vModelDynamic, _ctx.inputValue],
        [_directive_maska, _ctx.mask]
      ]),
      _withDirectives(_createElementVNode("div", {
        class: "cursor-pointer",
        onClick: _cache[3] || (_cache[3] = ($event: any) => {_ctx.showPassword = !_ctx.showPassword; _ctx.alterVisibility})
      }, [
        _createVNode(_component_icon, {
          class: "z-10 absolute top-4 right-1 bg-gray-400 h-4 w-4",
          icon: _ctx.icon
        }, null, 8, ["icon"])
      ], 512), [
        [_vShow, _ctx.type === 'password']
      ])
    ]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true),
    (_ctx.campoDisabledError)
      ? (_openBlock(), _createElementBlock("p", _hoisted_6, "Este campo é obrigatório"))
      : _createCommentVNode("", true),
    (_ctx.erroCep)
      ? (_openBlock(), _createElementBlock("p", _hoisted_7, "Cep não encontrado"))
      : _createCommentVNode("", true)
  ]))
}