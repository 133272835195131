import Constants from "@/enums/constants";
import { reactive } from "@vue/reactivity";



const semana = ["Seg", "Ter", "Qua", "Qui", "Sex", "Sáb", "Dom"];
const semanaTra = ["Dom","Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
const meses = [ "","Janeiro","Fevereiro","Março","Abril","Maio","Junho",
    "Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"];

class ConsultaRelatorioViewModel {
    
    private cnpj: string | null = localStorage.getItem(Constants.CHAVE_CNPJ);
    private bodyConsultaRelatorio = reactive({ 
        form:
        {
            cnpjCfc: this.cnpj ? this.cnpj: "",
            dtInicial: this.verificaDataAtual(),
            dtFinal: this.verificaDataAtual(),
        }
    });

    public get form(): consultaRelatorioDto {
        return this.bodyConsultaRelatorio.form;
    }

    public set form(form: consultaRelatorioDto) {
        this.bodyConsultaRelatorio.form = form;
    }
    
    private state = reactive({ 
        calendario:{
            mostrarCalendario: false,
        }
    });

    private paginacao = reactive({ 
        paginas: {
            paginaAtual:"",
            quantidadePaginas:0
        }
    });
    
    public get paginas(): paginas {
        return this.paginacao.paginas;
    }

    public set paginas(paginaAtual: paginas) {
        this.paginacao.paginas = paginaAtual;
    }

    private data = reactive({ 
        data: this.verificaDiaAtual()
    });

    public get exibirCalendario(): calendarioDto {
        return this.state.calendario;
    }

    public set exibirCalendario(calendario: calendarioDto) {
        this.state.calendario = calendario;
    }

    public get exibirData(): string {
        return this.data.data;
    }

    public set exibirData(dataAtual: string) {
        this.data.data = dataAtual;
    }
    

    public verificaDiaAtual (): string{
        const data = new Date();
        const dia = String(data.getDate()).padStart(2, '0');
        const mes = String(data.getMonth() + 1).padStart(2);
        const ano = data.getFullYear();
        return semanaTra[data.getDay()] +', ' + dia + ' de ' + meses[parseInt(mes)] + ' de ' + ano;            
    }

    public verificaDataAtual (): string{
        const data = new Date();
        const dia = String(data.getDate()).padStart(2, '0');
        const mes = String(data.getMonth() + 1).padStart(2,'0');
        const ano = data.getFullYear();
        return `${dia}/${mes}/${ano}`;             
    }

    public retornarDataAtual (): Date{
        return new Date();
    }

    public retornarData(data: string) {
        
        const date = new Date(data);
        const ano = date.getFullYear();
        const mes = meses[date.getMonth() + 1];
        const dia = date.getDate();
        const diaSemana = semana[date.getDay()];
              
        if(data != "")
            this.data.data = `${diaSemana}, ${dia} de ${mes} ${ano}`;
    }

    public retornarDataFormatada(data: string) {
        
        const date = new Date(data);
        const ano = date.getFullYear();
        const mes = String(date.getMonth() + 1).padStart(2,'0');
        const dia = String(date.getDate()).padStart(2,'0');
        return `${dia}/${mes}/${ano}`;
    }

    setValuesLocalStorage() {
        this.cnpj = localStorage.getItem(Constants.CHAVE_CNPJ);
        this.form.cnpjCfc = this.cnpj ? this.cnpj: "";
    }
}

export default new ConsultaRelatorioViewModel();

export interface consultaRelatorioDto  {
    cnpjCfc: string,  
    dtInicial: string, 
    dtFinal: string,  
}
export interface calendarioDto  {
    mostrarCalendario: boolean,
}

export interface paginas  {
    paginaAtual: string,
    quantidadePaginas: number,
}


