<template>
    <modal-factory></modal-factory> 
    <router-view v-slot="{ Component }">
        <transition mode="out-in">
            <component :is="Component" />
        </transition>
    </router-view>
</template>
<script lang="ts">
import  { defineComponent } from 'vue';
import ModalFactory from '@/components/modais/ModalFactory.vue';

export default defineComponent({
    name: 'App',
    components: { 
        ModalFactory
            
    },
});
</script>
