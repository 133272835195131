import axios from 'axios';
import qs from 'qs';
import constants from '@/enums/constants';

const baseUrl = process.env.VUE_APP_API_PREFIX + process.env.VUE_APP_API_URL;
const tokenParse = localStorage.getItem(constants.CHAVE_TOKEN);
const token = tokenParse ? tokenParse : '';

const http =  axios.create({
    timeout: 30000,
    baseURL: baseUrl,
    headers: {
        'Authorization': 'Bearer ' + token
    },
    paramsSerializer: params => qs.stringify(params)
   
});

export default http;