import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import '@/assets/css/animated.css';
import '@/assets/css/main.css';
import { dateFormatInput } from '@/utils/DateFormat';
import VCalendar from "v-calendar";
import alert from '@/hooks/alert';

const app = createApp(App);
app.use(router);
app.use(VCalendar);
app.use(alert);

app.config.globalProperties.$filters = {
    dateBRL(value: string) {
        return dateFormatInput(value);
    },
    currencyBRL(value: number) {
        const stringValue = (value/1).toFixed(2).replace('.', ',');
        return 'R$ ' + stringValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatTelefone(value: string) {
        const formatNumber = value.length === 11 ? value.match(/(\d{2})(\d{5})(\d{4})/) : value.match(/(\d{2})(\d{4})(\d{4})/);
        if (formatNumber) {
            const finalNumber = '('+ formatNumber[1] +') '+formatNumber[2]+'-'+formatNumber[3];
            return finalNumber;
        }
        return '';
    },
    formatCpf(value: string) {

        let valor = value;
        if(value.length == 10)
            valor = "0" +value;
             
        const cfpFormat = valor.replace(/\D/, "");
            
        const cpfValid = /(\d{3})(\d{3})(\d{3})(\d{2})/;

        if (cfpFormat.length == 11) {
            return cfpFormat.replace(cpfValid, "$1.$2.$3-$4");
        } else {
            return '';
        }
    },
    formatCep(value: string) {
        const cepFormat = value.replace(/\D/, "");
        const cpfValid = /(\d{5})(\d{3})/;

        if (cepFormat.length == 8) {
            return cepFormat.replace(cpfValid, "$1-$2");
        } else {
            return '';
        }
    }
};
app.mount("#app");
