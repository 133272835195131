import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3cadea59"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["checked", "name", "id", "disabled"]
const _hoisted_3 = {
  key: 0,
  class: "flex items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("label", {
    for: _ctx.id,
    class: "w-full flex-none",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onPressed && _ctx.onPressed(...args)))
  }, [
    _createElementVNode("input", {
      type: "radio",
      checked: _ctx.radioValue == _ctx.modelValue,
      name: _ctx.name,
      id: _ctx.id,
      class: "hidden",
      disabled: _ctx.disabled
    }, null, 8, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass(["btn flex justify-center items-center px-2+", [_ctx.error ? ['text-error-color outline-none border border-error'] : '', 
                      _ctx.classSize, _ctx.classColor] ])
    }, [
      (_ctx.icons)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.icons, (icon, index) => {
              return (_openBlock(), _createBlock(_component_Icon, {
                size: "18px",
                class: "mr-1",
                key: index,
                icon: icon
              }, null, 8, ["icon"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
    ], 2)
  ], 8, _hoisted_1))
}