<template>
    <!-- == Label == -->
    <label :for="id" class="w-full flex-none" @click="onPressed">
        <!-- == Input == -->
        <input
            type="radio" 
            :checked="radioValue == modelValue"  
            :name="name"
            :id="id"
            class="hidden"
            :disabled="disabled"
        />

        <!-- == Text Label == -->
        <div class="btn flex justify-center items-center px-2+"
             :class="[error ? ['text-error-color outline-none border border-error'] : '', 
                      classSize, classColor] ">
                      
            <div v-if="icons" class="flex items-center">
                <!-- Icones -->
                <Icon size="18px" class=" mr-1"
                      v-for="(icon, index) in icons" :key="index" 
                      :icon="icon" />    
            </div>
            
            {{ label }}         
        </div>
    </label>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { Icon } from '@/components';

const Radio = defineComponent({
    name: 'Radio',
    components: {
        Icon
    },

    emits: ['update:modelValue'],

    props: {
        modelValue: {
            required: true
        },

        radioValue: [String, Number, Boolean],
        label: {
            type: String,
        },
        name: {
            type: String,
            defaul: ''
        },
        check: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            require: true
        },
        color: {
            type: String,
            default: 'default'
        },
        size: {
            type: String,
            default: 'base'
        },
        icons: {
            type: Array as PropType<string[]>,
            default: () => []
        },
        disabled: {
            type:  Boolean,
            default: false,
        }

    },

    setup(props, context) {
        const selecteditem = ref(false); 

        const onPressed = () => {
            if (props.disabled == false) {
                context.emit('update:modelValue', props.radioValue);
            }
        };

                
        const classColor = computed(() => {
            let color: { [key: string]: string } = {
                default: 'bg-gray-200 text-black text-opacity-80 outline-none',
                primary: 'bg-primary text-white',
                custom: 'rounded-full   bg-gray-200',
                check: 'border-yellow-500 border'
                
            };
            return color[props.color];
        });
       
        const classSize = computed(() => {
            let color: { [key: string]: string } = {
                check: 'h-6 w-6 rounded-full ',
                xs: 'h-4 w-16 py-3 text-sm',
                rs: 'h-4 w-20 py-3 text-sm',
                sm: 'h-8 text-sm',
                xl: 'h-4 w-28 py-3 text-sm',
                base: 'h-11 font-semibold text-sm'
                
            };
            return color[props.size];
        });

        return {  
            selecteditem, 
            classColor,
            classSize,
            onPressed
        };
    }
});
export default Radio; 
</script>
<style scoped lang="postcss">
input:checked + div {
    @apply text-white bg-primary;
}
</style>
