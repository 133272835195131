import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("img", {
      src: 'data:image/png;base64,'+ _ctx.codigo,
      alt: "Código de Barra"
    }, null, 8, _hoisted_1)
  ]))
}