import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "flex flex-col overflow-hidden bg-white rounded-2xl" }
const _hoisted_2 = { class: "flex flex-col bg-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (_ctx.vmModal.ativarModal)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["stop"]), ["enter"])),
          class: "fixed top-0 left-0 flex items-center justify-center w-full h-full z-50 bg-black bg-opacity-50"
        }, [
          _createElementVNode("div", {
            class: "fixed mx-10",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.vmModal.component)))
              ])
            ])
          ])
        ], 32))
      : _createCommentVNode("", true)
  ]))
}