import { ReexameResponseDto } from "../dtos/ReexameResponseDto";
import { reactive } from "@vue/reactivity";
import { ReexameDto } from "../dtos/ReexameDto";

import Constants from "@/enums/constants";

class ReexameViewModel {
    private cnpj: string | null = localStorage.getItem(Constants.CHAVE_CNPJ);
    private matricula: string | null = localStorage.getItem(Constants.CHAVE_MATRICULA);
    private senha: string | null = localStorage.getItem(Constants.CHAVE_SENHA);

    private state = reactive({ 
        form: {
            renach: "",
            empresaId: 3,
            matricula: Number((this.matricula)),
            senha: this.senha ? (this.senha) : "",
            cnpjCfc:  this.cnpj ? this.cnpj: "",
            codigoOperacao: 3,
            cfcId: 909,
            cfcFilialLadvId: 0,
            gerarUsuarioPilotar: false,
            cpf: "",
            nrRenach: '',
        },

        // Boleto   
        boleto: {
            atividadeRemunerada: false,
            codigoOperacao: 0,
            cpf: "",
            empresaId: 0,
            cnpjCfc: "",
            cfcId:0,
            cfcFilialLadvId: 0,
            nrRenach: "",
            gerarUsuarioPilotar: false,
            id: 0,
            matricula: 0,
            endereco: {
                logradouro: "",
                cep: "",
                complemento: "",
                numero:"",
                telefones: [
                    {
                        ddd: "",
                        numero: ""
                    },
                    {
                        ddd: "",
                        numero: ""
                    }
                ],
                bairro: {
                    codigo: "",
                    nomeBairro: "",
                    municipio: {
                        codigo: "",
                        descricao: "",
                        uf: 0
                    }
                },
                statusDistribuicao: 0,
            },
            retorno:{
                nomeCfc: "",
                categoriaAtual: 0,
                nrRegistro: 0,
                dataPrimeiraHabilitacao: "",
                dataValidadeCNH: "",
                condutor:{
                    codigoDocumento: "",
                    cpf: "",
                    dataNascimento: "",
                    email: "",
                    genero: 0,
                    nacionalidade: "",
                    nome: "",
                    nomePai: "",
                    nomeMae: "",
                    nrDocumento: "",
                    orgaoEmissorDocumento: "",
                    sexo: "",
                    ufDocumento: "",
                    naturalidade:{
                        codigo: "",
                        descricao: "",
                        uf: "",
                    }
                },
                dadosOc: {
                    clinica: {
                        codigoClinica: 0,
                        descricao: ""
                    },
                    
                    endereco: {
                        telefones: [
                            {
                                ddd: "",
                                numero: ""
                            },
                            {
                                ddd: "",
                                numero: ""
                            }
                        ],
                        bairro: {
                            codigo: "",
                            nomeBairro: "",
                            municipio: {
                                codigo: "",
                                descricao: "",
                                uf: 0
                            }
                        },
                        statusDistribuicao: "",
                    },
                    horario: {
                        hrInicio: "",
                        hrFinal: ""
                    },


                },
                dadosPc: {
                    clinica: {
                        codigoClinica: 0,
                        descricao: ""
                    },
            
                    endereco: {
                        telefones: [
                            {
                                ddd: "",
                                numero: ""
                            },
                            {
                                ddd: "",
                                numero: ""
                            }
                        ],
                        bairro: {
                            codigo: "",
                            nomeBairro: "",
                            municipio: {
                                codigo: "",
                                descricao: "",
                                uf: 0
                            }
                        },
                        statusDistribuicao: "",

                    },
                    horario: {
                        hrInicio: "",
                        hrFinal: ""
                    },
                },
                retorno:{
                    descricao: "",
                    id: 0,
                },
                servico:{
                    codigoServico: 0,
                    descricao: "",
                    valorServico: 0,
                    codigosDeBarras: {
                        codigoBarra: "",
                        codigoBarra1: "",
                        codigoBarra2: "",
                        codigoBarra3: "",
                        codigoBarra4: "",
                        imagem: "",
                    }, 
                    dataServico: "",
                    validadeServico: "",
                    dataAbertura: "",
                    dataEmissaoSsDr: "",
                    nomeUsuario: ""
                },
            },
            senha: "",           
        }
        
    });

    public get form(): ReexameDto {
        return this.state.form;
    }

    public set form(form: ReexameDto) {
        this.state.form = form;
    }

    public  cleanForm() {
        this.form = {
            renach: "",
            empresaId: 3,
            matricula: Number((this.matricula)),
            senha: this.senha ? (this.senha) : "",
            cnpjCfc:  this.cnpj ? this.cnpj: "",
            codigoOperacao: 3,
            cfcId: 909,
            cfcFilialLadvId: 0,
            gerarUsuarioPilotar: false,
            cpf: "",
            nrRenach: "",           
        };
        localStorage.removeItem(Constants.CHAVE_REEXAME_BOLETO);
    }

    public get boleto(): ReexameResponseDto {
        return this.state.boleto;
    }

    public set boleto(boleto: ReexameResponseDto) {
        this.state.boleto = boleto;
    }

    setValuesLocalStorage() {
        this.cnpj = localStorage.getItem(Constants.CHAVE_CNPJ);
        this.matricula = localStorage.getItem(Constants.CHAVE_MATRICULA);
        this.senha = localStorage.getItem(Constants.CHAVE_SENHA);

        this.state.form.senha = this.senha ? this.senha : '';
        this.state.form.cnpjCfc = this.cnpj ? this.cnpj : ''; 
        this.state.form.matricula = this.matricula ? Number(this.matricula) : NaN;
    }

    getSessionStore = (): ReexameResponseDto | null => {
        const session = localStorage.getItem(Constants.CHAVE_REEXAME_BOLETO);
        if (session === null) return null;
        return JSON.parse(session);
    };
    // Setar valor na localStorage.
    setItemStorage(value: ReexameResponseDto): void {
        localStorage.setItem(Constants.CHAVE_REEXAME_BOLETO, JSON.stringify(value));
    }
    
    getBoletoStore(): void {
        const dadosBoleto = this.getSessionStore();
        if (dadosBoleto !== null) {
            this.boleto = dadosBoleto;
        }
    }

    public limparDadosDoBoletoLocalStorage() {
        localStorage.removeItem(Constants.CHAVE_REEXAME_BOLETO);
    }
}


export default new ReexameViewModel();


