import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ffa40922"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center my-3" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["checked", "name", "id"]
const _hoisted_4 = { class: "ml-5 text-black text-opacity-80 w-full text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "flex items-center"
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["step-circle border-primary", {'step-done': _ctx.checked}])
      }, [
        _createElementVNode("input", _mergeProps(_ctx.$attrs, {
          type: "checkbox",
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.check())),
          checked: _ctx.checked,
          name: _ctx.name,
          id: _ctx.id,
          class: "hidden"
        }), null, 16, _hoisted_3),
        (_ctx.checked)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              icon: "check-ok",
              size: "13px",
              color: "white"
            }))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ], 8, _hoisted_2)
  ]))
}