import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, mergeProps as _mergeProps, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-20622cb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "mr-2 text-neutral w-full text-sm" }
const _hoisted_4 = ["checked", "name", "id", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.id,
      class: "flex items-center"
    }, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["focus:border-primary border w-5 h-5 rounded-sm flex-shrink-0 flex justify-center items-center", {'step-done': _ctx.checked}])
      }, [
        _createElementVNode("input", _mergeProps(_ctx.$attrs, {
          type: "checkbox",
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.check())),
          checked: _ctx.checked,
          name: _ctx.name,
          id: _ctx.id,
          class: "hidden",
          disabled: _ctx.disabled && !_ctx.checked
        }), null, 16, _hoisted_4),
        (_ctx.checked)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              icon: "check-ok",
              size: "9.5px",
              color: "white"
            }))
          : _createCommentVNode("", true)
      ], 2)
    ], 8, _hoisted_2)
  ]))
}