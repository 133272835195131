<template >
    <label>
        <input  type="checkbox"  class="hidden" v-model="consulta.selecionado" />
        <div :class="verificaSelecionado">{{nome}}</div>
    </label>
</template>

<script lang='ts'>
import { defineComponent,reactive,computed } from 'vue';


const Consulta = defineComponent({
    name: 'Consulta',
    components: {
       
    },

    props: {
        nome: {
            type: String,
            required: true
        },
        selecionado: {
            type: Boolean,
            required: true
        },
    },
    setup () {

        const consulta = reactive({
            selecionado: false,
            
        });
       
        const verificaSelecionado = computed((): string => {
            if(!consulta.selecionado)
            {
                return   'rounded-full btn-primary w-16 h-5 py-3 px-6 flex justify-center items-center bg-gray-200 text-black text-opacity-80 outline-none';
            }
           
            return  'rounded-full btn-gray w-16 h-5 py-3 px-6 flex justify-center items-center text-white btn-primary';
            
        });

        return {
            consulta,
            verificaSelecionado
        };
    }
});

export default Consulta;


</script>
<style>
</style>