<template>
    <PerfilMainView >
        <div class=" rounded-lg sm:rounded-lg px-6 py-10 bg-white w-4/6 ml-20">
            <header class="flex justify-center mb-4">
                <div class="mb-4 sm:mb-0 items-center">
                    <img  class="items-center" :src="logoPilotar" alt="Pilotar">
                </div>
            </header>

            <div >
                <h1 class="text-lg font-semibold tracking-wide flex justify-center mt-5">Selecione o serviço que deseja utilizar</h1>
                <transition name="fade" mode="out-in" appear>
                    <div class="  pl-8 ">   
                        <Button color="custom" 
                                class="h-12 w-45 text-white mr-9 mt-5"
                                @onPressed="redirecionarServico('dados')"
                        >
                            Abertura de RENACH
                        </Button>
                        <Button color="custom" 
                                class="h-12 w-45 text-white mr-9 mt-5"
                                @onPressed="redirecionarServico('adicao-mudanca')">
                            Adição e Mudança
                        </Button>
                        <Button color="custom" 
                                class="h-12 w-45 text-white mr-9 mt-5"
                                @onPressed="redirecionarServico('emissao')">
                            Emissão LADV
                        </Button>
                        <Button color="custom" 
                                class="h-12 w-45 text-white mr-9 mt-5"
                                @onPressed="redirecionarServico('reexame')">
                            Reexame
                        </Button>
                        <Button  color="custom"
                                 class="h-12 w-45  text-white mr-9 mt-5"
                                 @onPressed="redirecionarServico('consulta')">
                    
                            Consultas
                        </Button>
                    </div>   
                </transition>
            </div>   
        </div>
        
    </PerfilMainView>
</template>
<script lang='ts'>
import { defineComponent, ref, onMounted } from 'vue';
import { logoPilotar } from '@/assets/images';
import { Button } from '@/components';
import { useRouter } from 'vue-router';
import PerfilMainView from '@/views/perfil/PerfilMainView.vue';
import { required } from '@/utils/validators';
import GetToken from '@/services/GetToken';
import vmModal from '@/ViewModel/ModalViewModel';
import constants from '@/enums/constants';
type routerTypes = 'dados' | 'emissao'  | 'renovacao' |  'consultas' | 'adicao-mudanca' | 'reexame';
const Home = defineComponent({
    name: 'Home',
    components: {
        Button,
        PerfilMainView
    },
    setup () {
        const validate = ref(false);
        const router = useRouter();
        const matricula = ref("");
        const senha = ref("");
        const tokenParse = localStorage.getItem(constants.CHAVE_TOKEN);
        const token = tokenParse ? tokenParse : '';
        

        // Verificar matrícula e senha
        const verificarMatricula = () => {
            if (!vmModal.hasMatricula) {
                vmModal.ativarModal = true; 
                vmModal.message = 'Informe seus dados de acesso ao sistema do Detran.';
            } else {
                vmModal.ativarModal = false; 
            } 
        };
        // Verificar matrícula e senha
        const verificarToken = () => {
            GetToken.getToken((`${token}`)).then(() => {
                verificarMatricula();
            }).catch(() => {
                router.push('/');
            });
        };

        onMounted(() => {
            verificarToken();
        });
        
        const redirecionarServico = async (servico: routerTypes): Promise<void> => {
            router.push('/'+servico);
        };
        
        return {
            logoPilotar,
            redirecionarServico,
            matricula,
            senha,
            required,
            validate
        };
    }
});

export default Home;
</script>


