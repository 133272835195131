import Constants from "@/enums/constants";
import { reactive } from "@vue/reactivity";



class ModalViewModel {
    private matricula: string | null;
    private senha: string | null;

    constructor() {
        this.matricula = localStorage.getItem(Constants.CHAVE_MATRICULA);
        this.senha = localStorage.getItem(Constants.CHAVE_SENHA);
    }

    private state = reactive({
        usuarioCfc: {
            matricula: '',
            senha: ''
        },
        ativarModal: false,
        component: 'Acesso',
        isErro: false,
        message: ''
    });

    // Modal para acesso as requisições 
    public get ativarModal() {
        return this.state.ativarModal;
    }

    public set ativarModal(ativar: boolean) {
        this.state.ativarModal = ativar;
    }

    public get component() {
        return this.state.component;
    }

    public get usuarioCfc() {
        return this.state.usuarioCfc;
    }

    public set usuarioCfc(cfc: {matricula: string, senha: string}) {
        this.state.usuarioCfc = cfc;
    }

    public get hasMatricula() {
        this.matricula = localStorage.getItem(Constants.CHAVE_MATRICULA);
        return this.matricula !== null;
    }

    public get isErro() {
        return this.state.isErro;
    }

    public set isErro(isErro: boolean) {
        this.state.isErro = isErro;
    }

    public get message() {
        return this.state.message;
    }

    public set message(msg: string) {
        this.state.message = msg;
    }



    cleanState() {
        this.state = {
            usuarioCfc: {
                matricula: '',
                senha: ''
            },
            ativarModal: false,
            component: '',
            isErro: false,
            message: ''
        }; 
    }
}

export default new ModalViewModel();


