<template>
    <router-link to="/servico" tag="div">
        <header class="header-title--logo py-6">
            <h1 class="text-lg font-semibold tracking-wide">{{ title }}</h1>
            <div class="mb-4 sm:mb-0">
                <img :src="logoPilotar" alt="Logo do Pilotar">
            </div>
        </header>
    </router-link>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { logoPilotar } from '@/assets/images';

export default defineComponent({
    name: 'HeaderPilotar',
    props: {
        title: {
            type: String,
            require: true
        }
    },
    setup() {
        return { logoPilotar };
    }
});
</script>

