<template>
    <PerfilMainView >

        <div class=" rounded-lg sm:rounded-lg px-6 py-10 bg-white w-4/6 ml-20">
            <header class="flex justify-center mb-4">
                <div class="mb-4 sm:mb-0 items-center">
                    <img  class="items-center" :src="logoPilotar" alt="Pilotar">
                </div>
            </header>
            <div class="flex-row justify-center ml-5" >
                <h1 class="text-lg text-center mt-5 px-1">Para utilizar os serviços Pilotar, realize o login no SuperPrático.</h1>
            </div>

            <div class="  pl-8 ">
                <a href="https://admin-ba.superprati.co/"> 
                    <Button color="primary" 
                            class="h-12 w-45 text-white mr-9 mt-5" >
                        Fazer login
                    </Button>
                </a>
            </div>
        </div>

    </PerfilMainView>
</template>
<script lang='ts'>
import { defineComponent } from 'vue';

import { Button } from '@/components';
import { logoPilotar } from '@/assets/images';

import PerfilMainView from '@/views/perfil/PerfilMainView.vue';

const Login = defineComponent({
    name: 'Login',
    components: {
        Button,
        PerfilMainView

    },

    setup () {
        



        return {
            logoPilotar     
        };
    }
});

export default Login;
</script>


