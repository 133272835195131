<template>
   
    <div  v-if="mostrarCalendario" class="fixed top-0 left-0 flex items-center justify-center w-9/12 h-full z-50 bg-black bg-opacity-50">
        <div class="flex flex-col overflow-hidden bg-white rounded-2xl">

            <DatePicker 
                v-model="data" 
                locale="pt-br"
                color="yellow"
                :model-config="modelConfig"
                             
            />
            <div class="flex mb-2">

                <Button color="custom"
                        class="h-12 mt-2 ml-2 w-28 text-white"
                        @click="hideCalendario()"
                >                                  
                    Cancelar
                </Button>
                <Button color="custom"
                        class="h-12 2 ml-3 mt-2 w-28   text-white"
                        @click="$emit('consultarPeriodo')"
                >
                    Ok
                </Button>
            </div>
        </div>
    </div>
</template>
<script lang='ts'>
import {  ref, defineComponent, computed, watch } from 'vue';
import { DatePicker } from 'v-calendar';
import { Button } from '@/components';
import vmRelatorio from '@/ViewModel/ConsultaRelatorioViewModel';
import 'v-calendar/dist/style.css';

const Calendario = defineComponent({
    name: 'Calendario',
    components: {
        DatePicker,
        Button
    },
    emits: ['consultarPeriodo'],
      
    setup(){
        const mostrarCalendario = computed(() => vmRelatorio.exibirCalendario.mostrarCalendario);
        const data = ref( vmRelatorio.retornarData);
        const modelConfig = ref( { type: 'string',   mask: 'YYYY-MM-DD'    },  );  
        
       

        const hideCalendario = () => {
           
            vmRelatorio.exibirCalendario.mostrarCalendario = false;
        };


        
        watch(
            () => data.value,
            (count) => {
                vmRelatorio.retornarData(count.toString());
                let dataFormatoBr = vmRelatorio.retornarDataFormatada(count.toString());
                vmRelatorio.form.dtInicial = dataFormatoBr;
                vmRelatorio.form.dtFinal = dataFormatoBr;                
            }
        );


        return {
            data,
            modelConfig,
            mostrarCalendario,
            hideCalendario,
        };
    }
});
export default Calendario;
</script>
<style>
</style>