<template>
    <div class="w-full my-10 sm:my-2">
        <div class="w-full relative ">
            <!-- Label -->
            <label  :for="name" class="origin-left absolute -top-5 sm:-top-6 text-sm text-neutral-900"
                    :class="[error.length > 0 ? 'text-red-500' : 'text-neutral-900 truncate' ]">
                {{ $attrs['placeholder'] }}
            </label>

            <!-- Input -->
            <input v-model="inputValue"
                   :type="type === 'password' ? passwordType : type "
                   v-maska="mask"
                   @focus="$emit('focus', $event); inFocus = true"
                   @blur="$emit('blur', $event); inFocus = false"
                   :name="name"
                   :id="name"
                   :placeholder="active ? '' : hintText"
                   :disabled="disabled"
                   autocomplete="off"
                   class="w-full outline-none px-4 border rounded-md transition-all duration-200 ease-in-out transform 
                   focus:border-primary font-sans text-sm text-neutral-900 flex items-center bg-transparent h-11"
                   :class="[{ 'border-red-500 border': error.length > 0 || campoDisabledError || erroCep }, {'background-color': placeholderBackgroundColor }]" 
            />

            <!-- == Linha verde == -->
            <!-- <div class="absolute w-full -bottom-px border-b-2 transition-all duration-150 ease-in-out transform"
                 :class="[{'scale-x-0': !inFocus}, error.length > 0 ? 'border-red-500 scale-x-100' : 'border-primary']" 
            >
            </div> -->


            <!-- icon -->
            <div v-show="type === 'password'" class="cursor-pointer"   @click="showPassword = !showPassword; alterVisibility">
                <icon class="z-10 absolute top-4 right-1 bg-gray-400 h-4 w-4" 
                      :icon="icon" />   
            </div>

        </div>
        <!-- Error -->
        <p class="text-red-500 text-xs pt-1 text-left" v-if="error">{{ error }}</p>
        <p class="text-red-500 text-xs pt-1 text-left" v-if="campoDisabledError">Este campo é obrigatório</p>
        <p class="text-red-500 text-xs pt-1 text-left" v-if="erroCep">Cep não encontrado</p>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, inject, onMounted, PropType, ref, Ref, watch } from 'vue';
import { FormField } from './Form.vue';
import { maska } from 'maska';
import Icon from '@/components/Icon.vue';

// tokens: { 'Z': { pattern: '/[a-zA-Z\u00C0-\u00FF ]+/i' } }

const Input = defineComponent({
    directives: { maska },
    components: { Icon },
    props: {
        modelValue: {
            type: String,
            required: true
        },
        placeholderBackgroundColor: {
            type: String,
            default: 'none',
        },
        rules: {
            type: Array as PropType<((value: string) => string)[]>,
        },
        name: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        mask: {
            type: String
        },
        type: {
            type: String,
            default: 'text'
        },
        hintText: {
            type: String,
            default: ''
        },
        campoDisabledError: {
            type: Boolean,
            default: false
        },
        erroCep: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const addField = inject<(data: FormField) => null>("addField");
        const fields = inject<Ref<FormField[]>>('fields');
        const onInput = inject<() => void>('onInput');
        const icon = ref('eye-cross');
        const showPassword = ref(false);
        const inFocus = ref(false);
      
        watch(showPassword, (password) => {
            icon.value = password ? 'eye' : 'eye-cross'; 
        });

        const active = computed(() => {
            return inFocus.value && props.modelValue; 
        });
        

        const passwordType = computed(() => {
            return showPassword.value ? 'text' : 'password';
        });

        const error = computed(() => fields?.value.find(el => el.name == props.name)?.error || "");

        const inputValue = computed({
            get() {
                return props.modelValue;
            },
            set(value: any) {
                context.emit("update:modelValue", value);
                onInput && onInput();
            }
        });
      
        onMounted(() => {
            if(fields && props.rules && addField) {
                addField({ name: props.name, error: "", validation: props.rules });
            }
        });

        return { inputValue, 
            error, 
            active,
            inFocus, 
            icon, 
            showPassword, 
            passwordType };
    }
});

export default Input;
</script>

<style>
</style>