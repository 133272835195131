import constants from '@/enums/constants';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import CepDTO from '../DTOs/CepDTO';

const http: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_PREFIX + process.env.VUE_APP_API_URL,
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(constants.CHAVE_TOKEN)
    }
});

const DEFAULT_ERROR_MESSAGE: string = 'Serviço indisponível no momento, tente novamente em breve.';

export const GetCep = (cep: string): Promise<CepDTO> => {
    return http.get<CepDTO>("api/correios?cep=" + cep)
        .then((res: AxiosResponse<CepDTO>) => res.data)
        .catch((error: AxiosError) => {
            const errorMessage = error.response?.data?.error || DEFAULT_ERROR_MESSAGE;
            return Promise.reject(errorMessage);
        });
};
