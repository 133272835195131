enum Constants {
    CHAVE_STORE_BOLETO = 'RENOVACAO_CNH_BOLETO',
    CHAVE_STORE_FORM = 'RENOVACAO_CNH_FORM',
    CHAVE_TOKEN = 'TOKEN',
    CHAVE_CNPJ = 'CNPJ',
    CHAVE_EMISSAO_LADV_LAUDO = 'EMISSAO_LAUDO',
    CHAVE_EMISSAO_LADV_FORM = 'EMISSAO_FORM',
    CHAVE_NOME_INSTRUTOR = 'NOME_INSTRUTOR',
    CHAVE_SENHA = 'TOKEN_ACESSO',
    CHAVE_MATRICULA = 'MATRICULA',
    CHAVE_ABERTURA_RENACH_BOLETO = 'ABERTURA_RENACH_BOLETO',
    CHAVE_MUDANCA_ADICAO_BOLETO = 'CHAVE_ADICAO_MUDANCA_BOLETO',
    CHAVE_REEXAME_BOLETO = 'CHAVE_REEXAME_BOLETO',
}
export default Constants;