
<template >
    <div class="overflow-y-auto h-screen sm:overscroll-y-contain">
        <slot class="bg-gray-100"></slot>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

const Page = defineComponent({

});
export default { Page };
</script>
<style>
</style>