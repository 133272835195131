<template>
    <div class="w-full my-2">
        <div class="w-full relative">
            <!-- Label -->
            <label  :for="name" class="origin-left absolute  -top-5 sm:-top-6 text-sm truncate"
                    :class="'text-neutral-900'">
                {{ $attrs['placeholder'] }}
            </label>

            <!-- Input -->
            <select v-model="inputValue"
                    @focus="$emit('focus', $event); inFocus = true"
                    @blur="$emit('blur', $event); inFocus = false"
                    @click="inFocus = true"
                    @input="inFocus = true"
                    :name="name"
                    :id="name"
                    :disabled="disabled"
                    class="w-full outline-none px-4 border rounded-md transition-all duration-200 ease-in-out transform
                   focus:border-primary font-sans text-sm text-neutral-900 flex items-center bg-transparent h-11"
                    :class="[{ 'border-red-500 border': error.length > 0 }, {'background-color': placeholderBackgroundColor }]" >
                <option v-for="(item, index) in options" 
                        :key="index" :value="item.name"
                        class="overflow-y-auto " > 
                    <p class="truncate">{{ item.value }}</p>
                </option>
            </select>
            <!-- <transition name="fade" mode="out-in"> -->
            <!-- <Icon v-if="inFocus" icon="up" color="#FBB736" size="15px" class="absolute right-3 top-4"/>
            <Icon v-else icon="down" color="#363738" size="15px" class="absolute right-3 top-4"/> -->
            <!-- </transition> -->
        </div>
        <!-- Error -->
        <p class="text-red-500 text-xs pt-1 text-left" v-if="error">{{ error }}</p>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, inject, onMounted, PropType, ref, Ref, watch } from 'vue';
import { FormField } from './Form.vue';
import { UF } from '@/interfaces/propTypes/UF';

const Input = defineComponent({
    name: 'Select',
    components: {
    },
    props: {
        modelValue: {
            type: String,
            required: true
        },
        options: {
            type: Array as PropType<UF[] | string[]>,
            require: true
        },
        label: {
            type: String,
            require: true
        },
        placeholderBackgroundColor: {
            type: String,
            default: 'none',
        },
        disabled: {
            type: Boolean,
            default: false
        },
        rules: {
            type: Array as PropType<((value: string) => string)[]>,
        },
        name: {
            type: String,
            required: true
        },
        error: {
            type: String,
            default: ''
        },
    },
    setup(props, context) {
        const addField = inject<(data: FormField) => null>("addField");
        const fields = inject<Ref<FormField[]>>('fields');
        const onInput = inject<() => void>('onInput');
        const inFocus = ref(false);
      

        const active = computed(() => {
            return inFocus.value && props.modelValue; 
        });

        // verifica se o select possui item selecionado, atribuir falso a variavel inFocus
        watch(() => props.modelValue, (vl) => {
            if (vl) inFocus.value = false;
        });
        
        // const error = computed(() => fields?.value.find(el => el.name == props.name)?.error || "");

        const inputValue = computed({
            get() {
                return props.modelValue;
            },
            set(value: any) {
                context.emit("update:modelValue", value);
                onInput && onInput();
            }
        });
      
        onMounted(() => {
            if(fields && props.rules && addField) {
                addField({ name: props.name, error: "", validation: props.rules });
            }
        });

        return { inputValue, 
            // error, 
            active,
            inFocus, 
        };
    }
});

export default Input;
</script>

<style>
</style>