<template> 
    <div class="bg-neutral-200" >
        <div class="text-sm  w-boleto mx-auto bg-white ">
            <div class="py-16">
                <!-- @audit-info guia do Detran -->
                <!-- #region == Via do Detran == -->
                <div class="relative box-border flex flex-nowrap">
                    <small class="text-xs font-semibold bg-gray-400 inset-2
                            border-l-2 border-t-2 border-r-2 border-black p-0.5">
                        VIA DO DETRAN
                    </small>
                    <div class="relative text-sm font-bold ml-1 border-b-0 bg-transparent">
                        <small>
                            Emitido pela Vsoft
                        </small>
                    </div>
                </div>
                <div class="relative">
                    <!-- // TODO: código de barra -->
                    <div class="absolute right-0 -top-5">
                   
                        <p> {{boletoCodigo.codigoBarra1}} {{boletoCodigo.codigoBarra2}} {{boletoCodigo.codigoBarra3}} {{boletoCodigo.codigoBarra4}}</p>
                    </div>

                    <!-- row 1 -->
                    <div class="h-26 border-2 border-black pl-1 pb-1" style="height: 75px;">
                        <div class="row relative">

                            <div class=" h-26 flex flex-col justify-between items-center 
                                absolute left-0 border-r-2 border-black px-2">
                                <figure class="w-14 h-16 mt-3" style="margin-top: 5px;">
                                    <img src="@/assets/images/logo-detran-ba.png" alt="DETRAN-BA">
                                </figure>
                            </div>

                            <p class="w-96 left-20 text-xs font-bold ml-2 pt-1 absolute">
                                GOVERNO DO ESTADO <br/>
                                SECRETARIA DE ADMINISTRAÇÃO <br/>
                                DEPARTAMENTO ESTADUAL DE TRÂNSITO <br/>
                                DH - SOLICITAÇÃO DE SERVIÇO
                            </p>
            
                            <!-- // TODO: informar nome do atendente. -->
                            <div class="pt-1 h-26 flex absolute left-80"> 
                                <div class="text-xs flex items-end">
                                </div>
                            </div>

                            <!-- // TODO: informar datas -->
                            <div class="absolute right-40 h-19 flex flex-col justify-between">
                                <div class="flex">
                                    <small class="mr-7 font-bold">Emissão: </small>
                                    <small>{{$filters.dateBRL(boleto.retorno.servico.dataEmissaoSsDr)}}</small>
                                </div>
                                <div class="flex">
                                    <small class="mr-6 font-bold">Abertura:</small>
                                    <small>{{$filters.dateBRL(boleto.retorno.servico.dataAbertura)}}</small>
                                </div>
                                <div class="flex">
                                    <small class="mr-6 font-bold">Validade:</small>
                                    <small class="font-bold">{{ $filters.dateBRL(boleto.retorno.servico.validadeServico)}}</small>
                                </div>
                            </div>
                            <!-- Pagar até:  -->
                            <div class="right-0 w-32 h-19 border-l-2 border-b-2 border-black absolute text-xs">
                                <div class="text-center border-b-black">
                                    <p class="font-semibold bg-gray-400">RENACH</p>
                                </div>
                                <div class="text-center font-semibold border-b-black">
                                    <p>{{boleto.nrRenach}}</p>
                                </div>
                                <div>
                                    <small class="font-semibold pl-1">Pagar até</small>
                                    
                                    <p class="text-center font-bold">{{ $filters.dateBRL(boleto.retorno.servico.validadeServico) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- row 2 OK -->
                    <div class="w-full border-lbr">
                        <div class="row grid-12-columns" style="height: 25px;">
                            <div class="col-span-6 px-1">
                                <small class="headline-sm-bold"><strong>Serviço</strong></small>
                                <p class="text-body-sm" style="margin-top: -5px;"> {{ boleto.retorno.servico.descricao }}</p>
                            </div>
                            <!-- <div class="col-span-1 px-1 border-l-black">
                            <small class="headline-sm-bold"><strong>PGI</strong></small>
                            <p class="text-body-sm">{}</p>
                        </div>
                        <div class="col-span-1 px-1 border-l-black">
                            <small class="headline-sm-bold"><strong>Registro</strong> </small>
                            <p class="text-body-sm">{}</p>
                        </div> -->
                            <div class="col-span-3 px-1 border-l-black">
                                <small class="headline-sm-bold"><strong>CPF</strong></small>
                                <p class="text-body-sm" style="margin-top: -5px;">{{ $filters.formatCpf(condutor.cpf) }}</p>
                            </div>
                            <div class="col-span-3 px-1 border-l-black">
                                <small class="headline-sm-bold"><strong>Carteira de Identidade</strong></small>
                                <p class="text-body-sm" style="margin-top: -5px;">{{ condutor.nrDocumento }} / {{ condutor.orgaoEmissorDocumento }}/{{condutor.ufDocumento}}</p>
                            </div>
                        </div>
                    </div>

                    <!-- row 3 OK -->
                    <div class="w-full border-lbr">
                        <div class="row grid-12-columns" style="height: 25px;">
                            <div class="col-span-4 px-1">

                                <small class="headline-sm-bold"><strong>Nome</strong></small>
                                <p class="truncate text-body-sm" style="margin-top: -5px;">{{condutor.nome}}</p>
                            </div>
                            <div class="col-span-2 px-1 border-l-black">
                                <small class="headline-sm-bold"><strong>Nascimento</strong></small>
                                <p class="text-body-sm" style="margin-top: -5px;">{{ $filters.dateBRL(condutor.dataNascimento) }}</p>
                            </div>
                            <div class="col-span-2 px-1 border-l-black">
                                <small class="headline-sm-bold"><strong>Cartegoria Pretendida</strong></small>
                                <p class="text-body-sm" style="margin-top: -5px;">{{ boleto.retorno.categoriaAtual }}</p>
                            </div>
                            <div class="col-span-2 px-1 border-l-black">
                                <small class="headline-sm-bold"><strong>Categoria Atual</strong></small>
                                <p class="text-body-sm" style="margin-top: -5px;">{{ boleto.retorno.categoriaAtual }}</p>
                            </div>
                        </div>
                    </div>

                    <!-- row 4 OK -->
                    <div class="w-full border-lbr">
                        <div class="row grid-12-columns" style="height: 28px;">
                            <div class="col-span-4 px-1">
                                <small class="headline-sm-bold" ><strong>Mãe</strong></small>
                                <p class="text-body-sm" style="margin-top: -5px;">{{ condutor.nomeMae }}</p>
                            </div>
                            <div class="col-span-4 px-1 border-l-black">
                                <small class="headline-sm-bold"><strong>Pai</strong></small>
                                <p class="text-body-sm" style="margin-top: -5px;">{{ condutor.nomePai }}</p>
                            </div>
                            <div class="col-span-2 ml-3 px-1 border-l-black">
                                <small class="headline-sm-bold"><strong>A.Remunerada </strong></small>
                                <p class="text-body-sm" style="margin-top: -5px;"> {{condutor.atividadeRemunerada == true ? "SIM" : "NÃO"}} </p>
                            </div>
        
                            <div class="col-span-2 px-1 border-l-black">
                                <small class="headline-sm-bold"><strong>Valor do serviço </strong></small>
                                <p class="text-body-sm text-price" style="margin-top: -5px;">{{ $filters.currencyBRL(boleto.retorno.servico.valorServico) }}</p>
                            </div>
                        </div>
                    </div>

                    <!-- row 5 OK -->
                    <div class="w-full border-lbr">
                        <div class="row grid-12-columns" style="height: 27px;">
                            <div class="col-span-5 px-1">
                                <small class="headline-sm-bold"><strong>Logradouro</strong></small>
                                <p class="text-body-sm" style="margin-top: -5px;">{{boleto.endereco.logradouro }} {{boleto.endereco.numero}}</p>
                            </div>
                            <div class="col-span-5 px-1 border-l-black">
                                <small class="headline-sm-bold"><strong>Complemento</strong></small>
                                <p class="text-body-sm" style="margin-top: -5px;">{{boleto.endereco.complemento}}</p>
                            </div>
                            <div class="col-span-2 px-1 border-l-black">
                                <small class="headline-sm-bold"><strong>Telefone</strong></small>
                                <p class="text-body-sm" style="margin-top: -5px;">({{boleto.endereco.telefones[0].ddd}}) {{boleto.endereco.telefones[0].numero}}</p>
                            </div>
                        </div>
                    </div>

                    <!-- row 6 OK -->
                    <div class="w-full border-lbr">
                        <div class="row grid-12-columns" style="height: 27px;">
                            <div class="col-span-6 px-1">
                                <small class="headline-sm-bold"><strong>Bairro / Município</strong></small>
                                <p class="text-body-sm" style="margin-top: -5px;">{{boleto.endereco.bairro.nomeBairro}} / {{boleto.endereco.bairro.municipio.descricao}} </p>
                            </div>
                            <!-- <div class="col-span-2 px-1 border-l-black">
                                <small class="headline-sm-bold"><strong>UF Origem</strong></small>
                                <p class="text-body-sm">{{ boleto.naturalidade.uf }}</p>
                            </div> -->
                            <div class="col-span-2 px-1 border-l-black">
                                <small class="headline-sm-bold"><strong>CEP </strong> </small>
                                <p class="text-body-sm" style="margin-top: -5px;">{{ $filters.formatCep(boleto.endereco.cep) }}</p>
                            </div>
                            <div class="col-span-2 px-1 border-l-black">
                                <small class="headline-sm-bold"><strong>Telefone</strong></small>
                                <p class="text-body-sm" style="margin-top: -5px;">({{ boleto.endereco.telefones[1].ddd}}) {{boleto.endereco.telefones[1].numero}}</p>
                            </div>
                        </div>
                    </div>

                    <!-- row 7 -->
                    <!-- <div class="w-full border-lbr">
                        <div class="row grid-12-columns">
                            <div class="col-span-6 px-1">
                                <small class="headline-sm-bold"><strong>Nacionalidade / Naturalidade</strong></small>
                                <p class="text-body-sm">{{ boleto.nacionalidade }} / {{ boleto.naturalidade.descricao }} </p>
                            </div>
                            <div class="col-span-5 px-1 border-l-black">
                                <small class="headline-sm-bold"><strong>Pais da 1° licença </strong></small>
                                <p class="text-body-sm">- - - - -</p>
                            </div>
                        </div>
                    </div> -->

                    <!-- row 8  - Observações -->
                    <div class="w-full border-lbr">
                        <div class="row grid grid-cols-12 relative h-4">
                            <div class="col-span-12 h-4 px-1">
                                <small class="headline-sm-bold" ><strong>Observações</strong></small>
                                <p class="pl-8 text-body-sm"></p>
                            </div>
                        </div>
                    </div>

                    <!-- row 9 - termos de serviço OK -->
                    <div class="w-full border-lbr">
                        <div class="row grid grid-cols-12 ">
                            <div class="col-span-12 px-1">

                                <p class="pl-8 pt-1 flex h-25">
                                    Eu,  {{ condutor.nome.toUpperCase() }}
                                    declaro a veracidade sobre todas as informações prestadas na solicitação do referido serviço
                                    e nos dados de contato tal qual endereço de residência de domicilio, intenção da emissão do serviço,
                                    além do exercício de atividade remunerada, ciente de que se falsas forem, sofrei sanções previstas 
                                    na legislação aplicável, conforme determina a lei 7115/83. 
                
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="absolute -bottom-5 left-4 box-border">
                        <small class="text-xs font-bold p-0.5">
                            Pagável na Internet ou qualquer agência/terminal de auto-atendimento Bradesco ou Banco do Brasil
                        </small>
                    </div>

                    <div class="absolute -bottom-5 right-0 box-border">
                        <small class="text-xs font-semibold bg-gray-400
                            border-l-2 border-t-2 border-b-2 border-r-2 border-black p-0.5">
                            Autenticação Mecânica
                        </small>
                    </div>

                </div> 
                <!--barcode // TODO: barcode -->
                <div class="mt-5 ml-5">
                    <Barcode :codigo="boletoImagem"/>
                </div>

                <!-- === #endregion FIM BLOCO 1  === -->


                <!-- linha divisor -->
                <div class="w-full my-7 border-b-2 border-dashed border-black relative" style="margin-top: 5px;">
                    <img class="absolute h-5 w-6 -top-3" 
                         src="@/assets/images/tesoura128.png" alt="DETRAN-BA">
                </div>

                <!-- @audit-info guia do cliente -->
                <!-- #region === Via do Cliente === -->

                <div>
                    <div class="relative">
                        <div class="relative box-border flex flex-nowrap ">
                            <small class="text-xs font-semibold bg-gray-400 inset-2
                                border-l-2 border-t-2 border-r-2 border-black p-0.5">
                                VIA DO CLIENTE
                            </small>
                            <div class="relative text-sm font-bold ml-1 border-b-0 bg-transparent">
                                <small>
                                    Emitido pela Vsoft
                                </small>
                            </div>
                            <!-- // TODO: código referência topo -->
                            <div class="absolute right-0">
                                <p> {{boletoCodigo.codigoBarra1}} {{boletoCodigo.codigoBarra2}} {{boletoCodigo.codigoBarra3}} {{boletoCodigo.codigoBarra4}}</p>
                            </div>
                        </div>

                        <!-- row 1 OK -->
                        <div class="h-26 border-2 border-black pl-1 pb-1" style="height: 75px;">
                            <div class="row relative">
                                <div class=" h-26 flex flex-col justify-between items-center 
                                        absolute left-0 border-r-2 border-black px-2">
                                    <figure class="w-14 h-16 mt-3" style="margin-top: 5px;">
                                        <img src="@/assets/images/logo-detran-ba.png" alt="DETRAN-BA">
                                    </figure>
                                </div>

                                <p class="w-96 left-20 text-xs font-bold ml-2 pt-1 absolute">
                                    GOVERNO DO ESTADO <br/>
                                    SECRETARIA DE ADMINISTRAÇÃO <br/>
                                    DEPARTAMENTO ESTADUAL DE TRÂNSITO <br/>
                                    DH - SOLICITAÇÃO DE SERVIÇO
                                </p>
                
                                <!-- // TODO: informar nome do atendente. -->
                                <div class="pt-1 h-26 flex absolute left-80"> 
                                    <div class="text-xs flex items-end">
                                        <!-- <p class="ml-3">GISELE BARBOSA DE OLIVEIRA - DETRAN-SEDE</p> -->
                                    </div>
                                </div>

                                <!-- // TODO: informar datas OK -->
                                <div class="absolute right-40 h-29 flex flex-col justify-between">
                                    <div class="flex">
                                        <small class="mr-7 font-bold">Emissão: </small>
                                        <small>{{$filters.dateBRL(boleto.retorno.servico.dataEmissaoSsDr)}}</small>
                                    </div>
                                    <div class="flex">
                                        <small class="mr-6 font-bold">Abertura:</small>
                                        <small>{{$filters.dateBRL(boleto.retorno.servico.dataAbertura) }}</small>
                                    </div>
                                    <div class="flex">
                                        <small class="mr-6 font-bold">Validade:</small>
                                        <small class="font-bold">{{ $filters.dateBRL(boleto.retorno.servico.validadeServico)}}</small>
                                    </div>
                                </div>

                                <div class="right-0 w-32 h-20 border-l-2 
                                border-b-2 border-black absolute text-xs">
                                    <div class="text-center border-b-black">
                                        <p class="font-semibold bg-gray-400">RENACH</p>
                                    </div>
                                    <div class="text-center font-semibold border-b-black">
                                        <p>{{boleto.nrRenach}}</p>
                                    </div>
                                    <div>
                                        <small class="font-semibold pl-1">Pagar até</small>
                                        <!-- TODO: data pagar até -->
                                        <p class="text-center font-bold">{{ $filters.dateBRL(boleto.retorno.servico.validadeServico)}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- row 2 OK -->
                        <div class="w-full border-lbr">
                            <div class="row grid-12-columns" style="height: 25px;">
                                <div class="col-span-6 px-1">
                                    <small class="headline-sm-bold"><strong>Serviço</strong></small>
                                    <p class="text-body-sm" style="margin-top: -5px;"> {{boleto.retorno.servico.descricao}}</p>
                                </div>
                                <!-- <div class="col-span-1 px-1 border-l-black">
                                <small class="headline-sm-bold"><strong>PGI</strong></small>
                                <p class="text-body-sm">{}</p>
                            </div> -->
                                <!-- <div class="col-span-1 px-1 border-l-black">
                                <small class="headline-sm-bold"><strong>Registro</strong> </small>
                                <p class="text-body-sm">{}</p>
                            </div> -->
                                <div class="col-span-3 px-1 border-l-black">
                                    <small class="headline-sm-bold"><strong>CPF</strong></small>
                                    <p class="text-body-sm" style="margin-top: -5px;">{{ $filters.formatCpf(condutor.cpf) }}</p>
                                </div>
                                <div class="col-span-3 px-1 border-l-black">
                                    <small class="headline-sm-bold"><strong>Carteira de Identidade</strong></small>
                                    <p class="text-body-sm" style="margin-top: -5px;">{{ condutor.nrDocumento }} / {{ condutor.orgaoEmissorDocumento }}/{{condutor.ufDocumento}}</p>
                                </div>
                            </div>
                        </div>

                        <!-- row 3 OK -->
                        <div class="w-full border-lbr">
                            <div class="row grid-12-columns" style="height: 28px;">
                                <div class="col-span-4 px-1">
                                    <small class="headline-sm-bold"><strong>Nome</strong></small>
                                    <p class="truncate text-body-sm" style="margin-top: -5px;">{{condutor.nome}}</p>
                                </div>
                                <div class="col-span-2 px-1 border-l-black">
                                    <small class="headline-sm-bold"><strong>Nascimento</strong></small>
                                    <p class="text-body-sm" style="margin-top: -5px;">{{ $filters.dateBRL(condutor.dataNascimento) }}</p>
                                </div>
                                <!-- <div class="col-span-2 px-1 border-l-black">
                                    <small class="headline-sm-bold"><strong>Sexo</strong> </small>
                                    <p class="text-body-sm">{{ sexo }}</p>
                                </div> -->
                                <div class="col-span-2 px-1 border-l-black">
                                    <small class="headline-sm-bold"><strong>Cartegoria Pretendida</strong></small>
                                    <p class="text-body-sm" style="margin-top: -5px;">{{ boleto.retorno.categoriaAtual }}</p>
                                </div>
                                <div class="col-span-2 px-1 border-l-black">
                                    <small class="headline-sm-bold"><strong>Categoria Atual</strong></small>
                                    <p class="text-body-sm" style="margin-top: -5px;"> {{ boleto.retorno.categoriaAtual}} </p>
                                </div>
                                
                                
                                <div class="col-span-1 px-1 border-l-black">
                                    <small class="headline-sm-bold"><strong>Valor do Serviço </strong></small>
                                    <p class="text-body-sm text-price" style="margin-top: -5px;">{{ $filters.currencyBRL(boleto.retorno.servico.valorServico) }}</p>
                                </div>
                            </div>
                        </div>

                        <!-- row 4 -->
                        <div class="w-full border-lbr">
                            <div class="row grid-12-columns" style="height: 26px;">
                                <div class="col-span-5 px-1">
                                    <small class="headline-sm-bold"><strong>Mãe</strong></small>
                                    <p class="text-body-sm" style="margin-top: -5px;">{{condutor.nomeMae}}</p>
                                </div>
                                <div class="col-span-5 px-1 border-l-black">
                                    <small class="headline-sm-bold"><strong>Pai</strong></small>
                                    <p class="text-body-sm" style="margin-top: -5px;">{{condutor.nomePai}}</p>
                                </div>
                                <div class="col-span-2 ml-3 px-1 border-l-black">
                                    <small class="headline-sm-bold"><strong>A.Remunerada </strong></small>
                                    <p class="text-body-sm" style="margin-top: -5px;"> {{condutor.atividadeRemunerada == true ? "SIM" : "NÃO"}} </p>
                                </div>
            
                            <!-- <div class="col-span-2 px-1 border-l-black">
                                <small class="headline-sm-bold"><strong>???????</strong></small>
                                <p class="text-body-sm font-bold text-center">{}</p>
                            </div> -->
                            </div>
                        </div>

                        <!-- row 5  - Observações -->
                        <div class="w-full border-lbr">
                            <div class="row grid grid-cols-12 relative h-4">
                                <div class="col-span-12 h-4 px-1">
                                    <small class="headline-sm-bold" ><strong>Observações</strong></small>
                                    <p class="pl-8 text-body-sm"></p>
                                </div>
                            </div> 
                        </div>

                        <!-- row 6 - serviços -->
                        <div class="w-full border-lbr">
                            <div class="row grid grid-cols-12">
                                <div class="col-span-5 px-1">
                                    <small class="" ><strong>Etapas a serem realizadas</strong></small>
                                </div>
                                <div class="col-span-7 px-1 border-l-black">
                                    <small class="" ><strong>Procedimentos</strong></small>
                                </div>
                            </div>
                        </div>

                        <!-- row 6 - serviços -->
                        <div class="w-full border-lbr">
                            <div class="row grid grid-cols-12 ">
                                <div class="col-span-5 px-1">

                                    <p class="pl-3 pt-1 flex h-27">
                                        <ol class="space-y-4 text-xs list-decimal  ml-1">
                                            <li>EXAME DE SANIDADE FISICA E MENTAL</li>
                                            <li>EXAME PSICOLOGICO</li>
                                            <li>EXAME DE LEGISLACAO</li>
                                            <li>EXAME DE DIRECAO VEICULAR E/OU 4 RODAS</li>
                                        </ol>
                    
                                    </p>
                                </div>
                                <div class="col-span-7 pt-1 px-1 border-l-black text-sm">
                                    <ul class="text-xs">
                                        <li>
                        
                                            {{ boleto.retorno.dadosOc.clinica.codigoClinica }} {{ boleto.retorno.dadosOc.clinica.descricao }} - 
                                            HORARIO AGENDAMENTO: {{ boleto.retorno.dadosOc.horario.hrInicio }} H AS {{ boleto.retorno.dadosOc.horario.hrFinal }}H
                                            RUA {{ boleto.retorno.dadosOc.endereco.bairro.nomeBairro }} MUNICIPIO {{ boleto.retorno.dadosOc.endereco.bairro.municipio.descricao }} /
                                            {{ boleto.retorno.dadosOc.endereco.bairro.municipio.uf }} ({{boleto.retorno.dadosOc.endereco.telefones[0].ddd}}) {{boleto.retorno.dadosOc.endereco.telefones[0].numero}}
                                        </li>
                                        <li>
                        
                                            {{ boleto.retorno.dadosPc.clinica.codigoClinica }} {{ boleto.retorno.dadosPc.clinica.descricao }} - 
                                            HORARIO AGENDAMENTO:  {{ boleto.retorno.dadosPc.horario.hrInicio }}H AS {{ boleto.retorno.dadosPc.horario.hrFinal }}H
                                            RUA {{ boleto.retorno.dadosPc.endereco.bairro.nomeBairro }} MUNICIPIO {{ boleto.retorno.dadosPc.endereco.bairro.municipio.descricao }} /
                                            {{ boleto.retorno.dadosPc.endereco.bairro.municipio.uf }} ({{boleto.retorno.dadosPc.endereco.telefones[0].ddd}}) {{boleto.retorno.dadosPc.endereco.telefones[0].numero}}
                        
                                        </li>
                                        <li>
                        
                                            DIRIJA-SE A UM CFC DE SUA ESCOLHA E SOLICITE
                                            45 HORAS DE AULAS TEORICAS
                                            MARCACAO DA PROVA DE LEGISLACAO
                        
                                        </li>
                                        <li>
                        
                                            DIRIJA-SE A UM CFC DE SUA ESCOLHA E SOLICITE 
                                            5h SIMULADOR/EMISSAO LADV/15h AULA PRATICA 
                                            EMISSAO LADV/20h AULAS PRATICAS 
                        
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <!--<div class="absolute -bottom-5 left-4 box-border">
                            <small class="text-xs font-bold p-0.5">
                                Pagável na Internet ou qualquer agência/terminal de auto-atendimento Bradesco ou Banco do Brasil
                            </small>
                        </div>

                        <div class="absolute -bottom-5 right-0 box-border">
                            <small class="text-xs font-semibold bg-gray-400
                            border-l-2 border-t-2 border-b-2 border-r-2 border-black p-0.5">
                                Autenticação Mecânica
                            </small>
                        </div> -->

                    <!-- == fim bloco 2 == -->
                    </div> 

                    <!--barcode // TODO: barcode 
                    <div class="mt-5 ml-5">
                        <Barcode :codigo="boletoImagem"/>
                    </div> -->

                    <!-- === #endregion FIM BLOCO 2  === -->

                    <!-- linha divisor -->
                    <div class="w-full my-7 border-b-2 border-dashed border-black relative" style="margin-top: 10px;">
                        <img class="absolute h-5 w-6 -top-3" 
                             src="@/assets/images/tesoura128.png" alt="DETRAN-BA">
                    </div>
                </div>
                <!-- @audit-info guia do banco -->
                <!-- === Via do Banco === -->
                <div>
                    <div class="relative">
    
                        <div class="absolute -top-5 box-border">
                            <small class="text-xs font-semibold bg-gray-400 
                                        border-l-2 border-t-2 border-r-2 border-black p-0.5">
                                VIA DO BANCO</small>
                            <small class="text-xs font-bold ml-1 mb-2 absolute -top-1 w-32">
                                Emitido pela Vsoft</small>
                        </div>

                        <!-- // TODO: código referência topo -->
                        <div class="absolute right-0 -top-5">
                            <p> {{boletoCodigo.codigoBarra1}} {{boletoCodigo.codigoBarra2}} {{boletoCodigo.codigoBarra3}} {{boletoCodigo.codigoBarra4}}</p>
                        </div>

                        <!-- row 1 -->
                        <div class="h-26 border-2 border-black pl-1 pb-1" style="height: 75px;">
                            <div class="row relative">

                                <div class=" h-26 flex flex-col justify-between items-center 
                                    absolute left-0 border-r-2 border-black px-2">
                                    <figure class="w-14 h-16 mt-3" style="margin-top: 5px;">
                                        <img src="@/assets/images/logo-detran-ba.png" alt="DETRAN-BA">
                                    </figure>

                                </div>

                                <p class="w-96 left-20 text-xs font-bold ml-2 pt-1 absolute">
                                    GOVERNO DO ESTADO <br/>
                                    SECRETARIA DE ADMINISTRAÇÃO <br/>
                                    DEPARTAMENTO ESTADUAL DE TRÂNSITO <br/>
                                    DH - SOLICITAÇÃO DE SERVIÇO
                                </p>
                
                                <!-- // TODO: informar nome do atendente. -->
                                <div class="pt-1 h-26 flex absolute left-80"> 
                                    <div class="text-xs flex items-end">
                                    </div>
                                </div>

                                <!-- // TODO: informar datas -->
                                <div class="absolute right-40 h-19 flex flex-col justify-between">
                                    <div class="flex">
                                        <small class="mr-7 font-bold">Emissão: </small>
                                        <small>{{$filters.dateBRL(boleto.retorno.servico.dataEmissaoSsDr)}}</small>
                                    </div>
                                    <div class="flex">
                                        <small class="mr-6 font-bold">Abertura:</small>
                                        <small>{{$filters.dateBRL(boleto.retorno.servico.dataAbertura)}}</small>
                                    </div>
                                    <div class="flex">
                                        <small class="mr-6 font-bold">Validade:</small>
                                        <small class="font-bold">{{ $filters.dateBRL(boleto.retorno.servico.validadeServico)}}</small>
                                    </div>
                                </div>

                                <!-- <div class="right-0 w-32 h-19 border-l-2 
                                        border-b-2 border-black absolute text-xs">
                                    <div class="text-center border-b-black">
                                        <p class="font-semibold bg-gray-400">{{boleto.nrRenach}}</p>
                                    </div>
                                    <div class="text-center font-semibold border-b-black">
                                        <p></p>
                                    </div>
                                    <div>
                                        <small class="font-semibold pl-1">Pagar até</small> -->
                                <!-- TODO: data pagar até -->
                                <!-- <p class="text-center font-bold">{{$filters.dateBRL(boleto.retorno.servico.validadeServico)}}</p>
                                    </div>
                                </div> -->
                                <div class="right-0 w-32 h-19 border-l-2 
                                    border-b-2 border-black absolute text-xs">
                                    <div class="text-center border-b-black">
                                        <p class="font-semibold bg-gray-400">RENACH</p>
                                    </div>
                                    <div class="text-center font-semibold border-b-black">
                                        <p>{{boleto.nrRenach}}</p>
                                    </div>
                                    <div>
                                        <small class="font-semibold pl-1">Pagar até</small>
                                        <!-- TODO: data pagar até -->
                                        <p class="text-center font-bold">{{ $filters.dateBRL(boleto.retorno.servico.validadeServico)}}</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <!-- row 2 -->
                        <div class="w-full border-lbr">
                            <div class="row grid-12-columns" style="height: 28px;">
                                <div class="col-span-5 px-1">
                                    <small class="headline-sm-bold"><strong>Serviço</strong></small>
                                    <p class="text-body-sm" style="margin-top: -5px;"> {{boleto.retorno.servico.descricao}}</p>
                                </div>
                                <div class="col-span-4 px-1 border-l-black">
                                    <small class="headline-sm-bold"><strong>Nome</strong></small>
                                    <p class="truncate text-body-sm" style="margin-top: -5px;">{{condutor.nome}}</p>
                                </div>
                                <div class="col-span-2 px-1 border-l-black">
                                    <small class="headline-sm-bold"><strong>Valor do Serviço </strong></small>
                                    <p class="text-body-sm text-price" style="margin-top: -5px;"> {{ $filters.currencyBRL(boleto.retorno.servico.valorServico) }}</p>
                                </div>
                            </div>
                        </div>


                        <div class="absolute -bottom-5 left-4 box-border" style="margin-bottom: 5px;">
                            <small class="text-xs font-bold p-0.5">
                                Pagável na Internet ou qualquer agência/terminal de auto-atendimento Bradesco ou Banco do Brasil
                            </small>
                        </div>

                        <div class="absolute -bottom-5 right-0 box-border" style="margin-bottom: 5px;">
                            <small class="text-xs font-semibold bg-gray-400 
                            border-l-2 border-t-2 border-b-2 border-r-2 border-black
                            p-0.5">
                                Autenticação Mecânica
                            </small>
                        </div>
                    <!-- fim bloco via do banco -->
                    </div>

                    <!--barcode  -->
                    <div class="mt-5 ml-5" style="margin-top: 15px;">
                        <Barcode :codigo= "boletoImagem"/>
                    </div>
                </div>
                
            </div>
           
        </div>
    </div>
</template>
<script lang='ts'>
import { defineComponent, computed, onMounted } from 'vue';
import Barcode from '@/components/template/Barcode.vue';
import CategoriaPretendida from '@/enums/categoriaPretendida';
import Estados from '@/enums/estados';
import vm from '../models/ReexameViewModel';
import Sexo from '@/enums/sexo';

const ReexamePDF = defineComponent({
    name: 'ReexamePDF',
    components: { Barcode },
    setup() {
        onMounted(() => {vm.getBoletoStore(); });// Aguarde a conclusão da função
        const boleto = computed(() => vm.boleto);
        const condutor = computed(() => vm.boleto.retorno.condutor);
        const boletoCodigo = computed(() => vm.boleto.retorno.servico.codigosDeBarras);
        const boletoImagem = computed(() => vm.boleto.retorno.servico.codigosDeBarras.imagem);
        const categoriaAtual = computed(() => {
            if (typeof vm.boleto.retorno.categoriaAtual === 'number') return CategoriaPretendida[vm.boleto.retorno.categoriaAtual];
            else return vm.boleto.retorno.categoriaAtual;
        });
        const ufOrigem = computed(() => {
            if (typeof vm.boleto.endereco.bairro.municipio.uf === 'number') return Estados[vm.boleto.endereco.bairro.municipio.uf];
            else return vm.boleto.endereco.bairro.municipio.uf;
        });
        const sexo = computed(() => {
            if (typeof vm.boleto.retorno.condutor.sexo === 'number') return Sexo[vm.boleto.retorno.condutor.sexo];
            else return vm.boleto.retorno.condutor.sexo;
        });

        return {
            ufOrigem,
            categoriaAtual,
            sexo,
            boleto,
            boletoCodigo,
            boletoImagem,
            condutor,
        };
    },
});

export default ReexamePDF;

</script>
<style lang="postcss">
.baseLayout{
    width: calc((40 / 2.54) * 300)px;
    height: calc((52 / 2.54) * 300)px;
}
.baseLayout2{
    width: 21.0cm;
    height: 29.7cm;
    padding: 2.97cm 2.1cm;
}
.baseLayout3{
    width: 793px;
    height: 1122px;
    padding: 20px 16px;
}
.row {
    @apply w-full flex;
}

.border-custom {
    @apply border-2 border-black;
}

.border-lbr {
    @apply border-l-2 border-r-2 border-b-2 border-black;
}

.border-b-black {
    @apply border-b-2 border-black;
}
.border-l-black {
    @apply border-l-2 border-black;
}

.grid-12-columns {
    @apply grid grid-cols-12 h-8 relative;
}

.headline-sm-bold{
    @apply absolute -top-1 tracking-tight;
    font-size: 10px;
}

.text-body-sm {
    @apply absolute top-4 text-xs;
}

.text-price {
    @apply font-bold text-sm;
}

html {
   height: 0;
}
  @media print {
    body {
        transform: scaley(0.65);
        transform-origin: 0 0;
    }
}
@page {
    margin: 15px;
    margin-left: 45px;
    margin-right: 45px;
}

@media screen ()  {
button 
{
 display: none;
}
}

</style>