import CfcDTO, { IEndereco, ILocalAtendimento, INaturalidade } from "@/DTOs/CfcDTO";
import BodyBoleto from "@/interfaces/BodyBoleto";
import { GetCep } from '@/services/GetCepService';
import { reactive } from "@vue/reactivity";

import Constants from "@/enums/constants";
import CepDTO from '@/DTOs/CepDTO';

class MainViewModel {
    private cnpj: string | null = localStorage.getItem(Constants.CHAVE_CNPJ);
    private matricula: string | null = localStorage.getItem(Constants.CHAVE_MATRICULA);
    private senha: string | null = localStorage.getItem(Constants.CHAVE_SENHA);

    private state = reactive({ 
        form: {
            matricula:  Number((this.matricula)),
            senha: this.senha ? (this.senha) : "",
            gerarUsuarioPilotar:false,
            cnpjCfc: this.cnpj ? this.cnpj: "",
            categoriaPretendida: 3,
            statusAcc: false,
            atividadeRemunerada: false,
            cpf: "",
            dataNascimento: "",
            nacionalidade: 1,
            nome: "",
            naturalidade: {
                codigo:"" , 
                descricao: "",
                uf: 5
            },
            nomePai:"" ,
            nomeMae: "",
            sexo: 1,
            genero: 3,
            codigoDocumento: 1,
            nrDocumento: "",
            orgaoEmissorDocumento:"" ,
            ufDocumento: 5,
            email: "",
            endereco: {
                logradouro: "",
                cep: "",
                complemento: "",
                numero: "",
                telefones: [
                    {
                        ddd: "",
                        numero: ""
                    },
                    {
                        ddd: "",
                        numero: ""
                    }
                ],
                bairro: {
                    codigo: "",
                    nomeBairro:"",
                    municipio: {
                        codigo: "",
                        descricao: "" ,
                        uf: 0 
                    }
                  
                },
                statusDistribuicao: 0
            },
            localAtendimento: {
                codigo: "",
                descricao: ""
            }
        },

        // Boleto   
        boleto: {
            retorno:{
                retorno:
            {
                id:0,
                descricao:""
            },
                servico:{
                    codigoServico: 0,
                    descricao: "",
                    valorServico: 0,
                    codigosDeBarras: {
                        codigoBarra: "",
                        codigoBarra1: "",
                        codigoBarra2: "",
                        codigoBarra3: "",
                        codigoBarra4: "",
                        imagem: "",
                    }, 
                    dataServico: "",
                    validadeServico: "",
                    dataAbertura: "",
                    dataEmissaoSsDr: "",
                    nomeUsuario: ""
                },
                nomeCfc:"",
                dadosOc: {
                    clinica: {
                        codigoClinica: 0,
                        descricao: ""
                    },
                    
                    endereco: {
                        telefones: [
                            {
                                ddd: "",
                                numero: ""
                            },
                            {
                                ddd: "",
                                numero: ""
                            }
                        ],
                        bairro: {
                            codigo: "",
                            nomeBairro: "",
                            municipio: {
                                codigo: "",
                                descricao: "",
                                uf: 0
                            }
                        },
                        statusDistribuicao: "",
                    },
                    horario: {
                        hrInicio: "",
                        hrFinal: ""
                    },


                },
                dadosPc: {
                    clinica: {
                        codigoClinica: 0,
                        descricao: ""
                    },
            
                    endereco: {
                        telefones: [
                            {
                                ddd: "",
                                numero: ""
                            },
                            {
                                ddd: "",
                                numero: ""
                            }
                        ],
                        bairro: {
                            codigo: "",
                            nomeBairro: "",
                            municipio: {
                                codigo: "",
                                descricao: "",
                                uf: 0
                            }
                        },
                        statusDistribuicao: "",

                    },
                    horario: {
                        hrInicio: "",
                        hrFinal: ""
                    },
                },
            },
            situacaoPagamento: "",
            categoriaPretendida: "",
            statusAcc: false,
            cpf: "",
            dataNascimento: "",
            nacionalidade: "",
            nome: "",
            naturalidade: {
                codigo: "",
                descricao: "",
                uf: ""
            },
            nomePai: "",
            nomeMae: "",
            sexo: "",
            genero: "",
            codigoDocumento: "",
            nrDocumento: 0,
            orgaoEmissorDocumento: "",
            ufDocumento: "",
            atividadeRemunerada: false,
            email: "",
            endereco: {
                logradouro: "",
                cep: "",
                complemento: "",
                numero:"",
                telefones: [
                    {
                        ddd: "",
                        numero: ""
                    },
                    {
                        ddd: "",
                        numero: ""
                    }
                ],
                bairro: {
                    codigo: "",
                    nomeBairro: "",
                    municipio: {
                        codigo: "",
                        descricao: "",
                        uf: 0
                    }
                },
                statusDistribuicao: ""
            },
            localAtendimento: {
                codigo: "",
                descricao: ""
            },
            gerarUsuarioPilotar: "",
            nrRenach: "",
            matricula: 0,
            senha: "",
            cnpjCfc: this.cnpj ? this.cnpj : "",
            empresaId: "",
            codigoOperacao: "",
            cfcId: 0,
            cfcFilialLadvId: 0,
        }
        
    });

    public get form(): CfcDTO {
        return this.state.form;
    }

    public set form(form: CfcDTO) {
        this.state.form = form;
    }

    public  cleanForm() {
        this.form = {
            matricula:  Number((this.matricula)),
            senha: this.senha ? (this.senha) : "",
            gerarUsuarioPilotar:false,
            cnpjCfc: this.cnpj ? this.cnpj: "",
            categoriaPretendida: 3,
            statusAcc: false,
            atividadeRemunerada: false,
            cpf: "",
            dataNascimento: "",
            nacionalidade: 0,
            nome: "",
            naturalidade: {
                codigo:"" , 
                descricao: "",
                uf: 0
            },
            nomePai:"" ,
            nomeMae: "",
            sexo: 1,
            genero: 0,
            codigoDocumento: 0,
            nrDocumento: "",
            orgaoEmissorDocumento:"" ,
            ufDocumento: 5,
            email: "",
            endereco: {
                logradouro: "",
                cep: "",
                complemento: "",
                numero: "",
                telefones: [
                    {
                        ddd: "",
                        numero: ""
                    },
                    {
                        ddd: "",
                        numero: ""
                    }
                ],
                bairro: {
                    codigo: "",
                    nomeBairro:"",
                    municipio: {
                        codigo: "",
                        descricao: "" ,
                        uf: 0 
                    }
                  
                },
                statusDistribuicao: 0
            },
            localAtendimento: {
                codigo: "",
                descricao: ""
            }
        };
        localStorage.removeItem(Constants.CHAVE_ABERTURA_RENACH_BOLETO);
    }

    public get boleto(): BodyBoleto {
        return this.state.boleto;
    }

    public set boleto(boleto: BodyBoleto) {
        this.state.boleto = boleto;
    }

    setValuesLocalStorage() {
        this.cnpj = localStorage.getItem(Constants.CHAVE_CNPJ);
        this.matricula = localStorage.getItem(Constants.CHAVE_MATRICULA);
        this.senha = localStorage.getItem(Constants.CHAVE_SENHA);

        this.state.form.senha = this.senha ? this.senha : '';
        this.state.form.cnpjCfc = this.cnpj ? this.cnpj : ''; 
        this.state.form.matricula = this.matricula ? Number(this.matricula) : NaN;
    }

    getSessionStore = (): BodyBoleto | null => {
        const session = localStorage.getItem(Constants.CHAVE_ABERTURA_RENACH_BOLETO);
        if (session === null) return null;
        return JSON.parse(session);
    };
    // Setar valor na localStorage.
    setItemStorage(value: BodyBoleto): void {
        localStorage.setItem(Constants.CHAVE_ABERTURA_RENACH_BOLETO, JSON.stringify(value));
    }
    
    getBoletoStore(): void {
        const dadosBoleto = this.getSessionStore();
        if (dadosBoleto !== null) {
            this.boleto = dadosBoleto;
        }
    }
   
    async searchCep(cep: string): Promise<void> {
        try {
            
            const address =  await GetCep(cep) as CepDTO;
            this.form.endereco.bairro.municipio.descricao = address.cidade;
            this.form.endereco.bairro.nomeBairro = address.bairro;
            this.form.endereco.logradouro = address.logradouro;
        }catch(e: any) {
            console.error(e.message ?? 'Cep não encontrado!');
        }
    } 

    // Busca dados de endereço
    setarEndereço = (address: CepDTO) => {
        this.state.form.endereco.bairro.municipio.descricao = address.cidade;
        this.state.form.endereco.bairro.nomeBairro = address.bairro;
        this.state.form.endereco.logradouro = address.logradouro;
        this.state.form.endereco.bairro.municipio.uf = parseInt(address.uf);
        
    };

    limparEndereco() {
        this.state.form.endereco.bairro.codigo = '';
        this.state.form.endereco.cep = '';
        this.state.form.endereco.bairro.municipio.descricao = '';
        this.state.form.endereco.bairro.nomeBairro = '';
        this.state.form.endereco.logradouro = '';
        this.state.form.endereco.bairro.municipio.uf = 5;
    }

}


export default new MainViewModel();

export interface MainModelState  {
    matricula: number,
    senha: string,
    gerarUsuarioPilotar:boolean,
    cnpjCfc:string,
    categoriaPretendida: number,
    statusAcc: boolean,
    atividadeRemunerada: boolean,
    cpf: string,
    dataNascimento: string,
    nacionalidade: number,
    nome: string,
    naturalidade: INaturalidade,
    nomePai: string,
    nomeMae: string,
    sexo: number,
    genero: number,
    codigoDocumento: number,
    nrDocumento: string,
    orgaoEmissorDocumento: string,
    ufDocumento: number,
    endereco: IEndereco,
    localAtendimento: ILocalAtendimento,
    email: string,

}

