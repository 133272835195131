<template>
    <div class="icon"
         @click="$emit('onToggle')"
         :style="[{'width': size},
                  {'height': size},
                  {'background-color': color},
                  {'mask-image': 'url('+ require('@/assets/icons/'+icon+'.svg')+')'}]">
                  <!-- {'mask-image': 'url('+ require('@/assets/icons/'+icon+'.svg')+')'}]"> -->

    </div>
</template>
<script>
import { defineComponent } from 'vue';

const Icon = defineComponent({
    name: 'Icon',
    props: {
        icon: {
            type: String,
            require: true,
        },
        size: {
            type: String,
            default: '20px'
        },
        color: {
            type: String,
            default: "#ACACAC"
        }
    }

});
export default Icon;
</script>   
<style>
.icon {
    display: block;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    -webkit-mask-size: contain;
    -webkit-transition: .3s;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    transition: .2s;
}

</style>