import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header-title--logo py-6" }
const _hoisted_2 = { class: "text-lg font-semibold tracking-wide" }
const _hoisted_3 = { class: "mb-4 sm:mb-0" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: "/servico",
    tag: "div"
  }, {
    default: _withCtx(() => [
      _createElementVNode("header", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.logoPilotar,
            alt: "Logo do Pilotar"
          }, null, 8, _hoisted_4)
        ])
      ])
    ]),
    _: 1
  }))
}