<template>
    <div>
        <img :src="'data:image/png;base64,'+ codigo" alt="Código de Barra">
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Barcode',
    props: {
        codigo: {
            type: String,
            require: true,
            defaul: ''
        }
    },
});
</script>