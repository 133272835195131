<template>
    <div class="  h-screen overflow-y-auto bg-image-group bg-no-repeat bg-cover p-3 sm:p-0 bg-primary">


        <div class="flex items-center relative bg-no-repeat"
             :class="['bg-image-iphones']">

            <!-- == Textos == -->
            <transition name="fade" mode="out-in" duration="300">
                <div class="h-screen hidden lg:flex px-8 flex-col w-2/4 items-center justify-center ">
               
                    <!-- == transition text 1 == -->
                    <div >
                        <h1 class="headline1">
                            Pilotar é grátis 
                        </h1>
                        <h1 class="headline1 ">
                            e 
                            <span class="text-neutral-900">Exclusivo</span>
                        </h1>
                        <h1 class="headline1  mb-4">
                            para alunos de CFC'S parceiros.
                        </h1>

                        <p class="headline4 ">
                            App com conteúdo 
                        </p>   
                        <p class="headline4 " >
                            para ajudar no processo 
                            de aprendizagem e avaliar o CFC.
                        </p>
                    </div>

                </div>
            </transition>
      
        
            <div class="w-full mx-auto max-w-2xl sm:py-5 relative z-0 visible">
                <slot></slot>  
            </div>


            <!-- == offset == -->
            <div class="hidden lg:flex w-2/4">
            </div>


        </div>
    </div>
</template>
<script lang='ts'>
import { defineComponent, ref } from 'vue';
import { validEmail, validPassword, required } from '@/utils/validators';

import { logoPilotar, groupVectorTop, groupIphone, groupVector,  iphone, simulatorVirtual } from '@/assets/images';
import { useRouter } from 'vue-router';

const PerfilMainView = defineComponent({
    name: 'PerfilMainView',
    components: {
        
    }, 

    setup () {
        const routeIndex = ref();
        const router = useRouter();

        const validate = ref(false);

        router.afterEach((to) => {
            routeIndex.value = to.meta.index;  
           
        });

        const submit = ( ) => {
            router.push({ name: 'contatos' });
        };

        return { 
            router,
            routeIndex,
            groupVectorTop,
            groupIphone,
            groupVector,
            simulatorVirtual,
            iphone,
            logoPilotar,
            validEmail, 
            submit,
            validate, 
            validPassword, 
            required 
        };
    }
});
export default PerfilMainView;
</script>

<style scoped>
.bg-image-group {
    background-image: url(../../assets/images/group-vector.png);
}
.bg-image-iphones {
    background-image: url(../../assets/images/cell-phones.png);
    background-size: 25.125rem 32.625rem;
    background-position: right 65%;
}

@media (max-width: 639px) {
    .bg-image-group {
        background-image: none;
    }
}

@media (max-width: 1168px) {
    .bg-image-iphones {
        background-image: none;
    }

}
</style>
