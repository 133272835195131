import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center p-7" }
const _hoisted_2 = { class: "text-xl font-medium mb-2" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "alert__detail"
}
const _hoisted_5 = { class: "w-full border-t border-gray-200 flex" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, { isOpened: _ctx.isOpened }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", { innerHTML: _ctx.message }, null, 8, _hoisted_3),
        (_ctx.detail.length)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
              _createElementVNode("small", null, _toDisplayString(_ctx.detail), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("footer", _hoisted_5, [
        (_ctx.actions.length == 0)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "outline-none h-16 flex items-center justify-center flex-1",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpened = false))
            }, " Ok "))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.actions, (option, index) => {
              return (_openBlock(), _createElementBlock("button", {
                class: _normalizeClass(["outline-none h-16 flex items-center justify-center flex-1 border-l first:border-l-0 border-gray-200 text-neutral-900", {'font-medium !text-primary': option.primary}]),
                key: index,
                onClick: ($event: any) => (_ctx.loadAction(option.action))
              }, _toDisplayString(option.title), 11, _hoisted_6))
            }), 128))
      ])
    ]),
    _: 1
  }, 8, ["isOpened"]))
}