<template>
    <div v-if="!loading" :class="[classColor]" @click="onPressed" 
         class="cursor-pointer rounded-md flex justify-center items-center focus:outline-none">
        <button :form="form"  class="w-full h-full focus:outline-none border-none font-semibold tracking-wide"
                :disabled="disabled"
        >
            <slot></slot>
        </button>
    </div>
    <div v-else class="bg-primary flex items-center text-white font-semibold px-5 mr-6 rounded">
        <svg class="animate-spin h-5 w-5 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
        </svg>
        <slot name="descricao-loading"/>
    </div>
</template>
<script lang='ts'>
import { computed, defineComponent } from 'vue';

const Button = defineComponent({
    name: 'Button',
    props: {
        color: {
            type: String,
            default: 'default'
        },
        type: {
            type: String,
            default: 'button'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        form: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    emits: ['onPressed'],

    setup(props, context){
        
        const classColor = computed(() => {
            let color: { [key: string]: string } = {
                default: 'bg-gray-200  outline-none',
                primary: 'bg-primary text-white hover:bg-primary-dark transform-all duration-100',
                custom: 'bg-primary border border-primary hover:bg-transparent hover:text-primary hover:font-semibold'
                         
            };

            return color[props.color];
        });

        const onPressed = () => {
            if (props.disabled == false) {
                context.emit('onPressed');
            }
        };

        return {
            onPressed,
            classColor
        };
    }
});
export default Button;
</script>
<style>
</style>