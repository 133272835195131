
<template>
    <!-- Corrigir esse componente. -->
    <div class="flex items-center"  >
        <label :for="id" class="flex items-center">
            <!-- == Label == -->
            <div class="mr-2 text-neutral w-full text-sm">
                <slot/>
            </div>

            <!-- == Input check == -->
            <div class="focus:border-primary border w-5 h-5 rounded-sm flex-shrink-0 flex justify-center items-center" :class="{'step-done': checked}">
                <input v-bind="$attrs"
                       type="checkbox"  
                       @change="check()" 
                       :checked="checked"
                       :name="name"
                       :id="id"
                       class="hidden"
                       :disabled="disabled && !checked"
                />
                <!-- <transition name="fade" mode="out-in"> -->
                <Icon v-if="checked" icon="check-ok" size="9.5px" color="white"/>
            <!-- </transition> -->
            </div>
        </label>
    </div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue';
import { Icon } from '@/components';

const CheckBox = defineComponent({
    name: 'CheckBoxSquare',
    components: {
        Icon
    },
    props: {
        value: {
            required: true
        },
        name: {
            type: String,
            defaul: ''
        },
        error: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            require: true
        },
        checkedBox: {
            type: Array,
            require: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    setup(props, context) {

        // verifica se possui valor no array do checkbox selecionado para validar.
        const checked = computed(() => props.checkedBox?.includes(props.value));

        function check() {

            // copia o array checkedBox
            let updateChecked = [...props.checkedBox!];

            // remove item se existe, senão adiciona o item selecionado.
            if (checked.value) {
                updateChecked.splice(updateChecked.indexOf(props.value), 1);
            } else {
                updateChecked.push(props.value);
            }

            // emitir a atualização do checkedBox
            context.emit('update:checkedBox', updateChecked);
        }
    
        return {
            check, 
            checked,
        };
    }
});
export default CheckBox; 
</script>
<style scoped lang="postcss">
/* input:isChecked + div {
    @apply text-white bg-primary;
} */
</style>
