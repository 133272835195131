<template >
    <div class="flex ">
        <div  v-for="(item,index) in qtdPaginas" :key="item.valor" >

            <div class="  w-6 h-6 ml-1 rounded-sm shadow-lg"
                 :class="{'bg-primary': item.selecionado}">
                <button   class="ml-2 "
                          :class="{'text-white': item.selecionado}"
                          @click="atualizarSelecionado(index)"
                > 
                    {{item.valor}} 
                </button>
            </div>
          
        </div>
      
        
    </div>
</template>
<script lang='ts'>
import { defineComponent, ref,onMounted, watch } from 'vue';
import vmRelatorio from '@/ViewModel/ConsultaRelatorioViewModel';

const Paginacao = defineComponent({
    name: 'Paginacao',
    components: {
       
    },
    emits: ['consultarPeriodoPaginacao'],                       


    setup (props, context) {
        onMounted(() => {
            
            qtdPaginas.value.splice(0,1);
                
        });

        const selecionado = ref();
        const qtdPaginas = ref([{ valor: 0, selecionado: false }]);
        let valorSelecionadoAnterior = 0;

        const atualizarSelecionado = (index: number) => {
            let valorRequestPaginacao = index + 1;
            vmRelatorio.paginas.paginaAtual = valorRequestPaginacao.toString();
            context.emit("consultarPeriodoPaginacao","");
            qtdPaginas.value[valorSelecionadoAnterior].selecionado = false;
            qtdPaginas.value[index].selecionado = true;
            valorSelecionadoAnterior = index;
        };

        watch(
            () => vmRelatorio.paginas.quantidadePaginas,
            () => {
                if(vmRelatorio.paginas.quantidadePaginas >1)
                {
                    for (var i = 1; i <= vmRelatorio.paginas.quantidadePaginas; i++) {
                        qtdPaginas.value.push({ valor: i, selecionado: false });
                    }
                    qtdPaginas.value[0].selecionado = true;
                }
                if(vmRelatorio.paginas.quantidadePaginas == 0)
                {
                    qtdPaginas.value = ([{ valor: 0, selecionado: false }]);
                    qtdPaginas.value.splice(0,1);
                }
            }
        );
        return { 
            selecionado,
            qtdPaginas,
            atualizarSelecionado,

        };
    }
});

export default  Paginacao ;
</script>