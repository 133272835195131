<template>
    <div class="container">
        <div class="border-1.5 border-gray-300 rounded-full mb-3" 
             :style="{ 'width': size, 'height': size }">
           
        </div>

        <!-- == Label == -->
        <div>
            <slot name="legend"></slot>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

const Step = defineComponent({
    name: 'Step',
    props: {
        size: {
            type: String,
            default: '30px'
        }
    }

});
export default Step; 
</script>
