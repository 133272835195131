import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed top-0 left-0 flex items-center justify-center w-9/12 h-full z-50 bg-black bg-opacity-50"
}
const _hoisted_2 = { class: "flex flex-col overflow-hidden bg-white rounded-2xl" }
const _hoisted_3 = { class: "flex mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.mostrarCalendario)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_DatePicker, {
            modelValue: _ctx.data,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data) = $event)),
            locale: "pt-br",
            color: "yellow",
            "model-config": _ctx.modelConfig
          }, null, 8, ["modelValue", "model-config"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Button, {
              color: "custom",
              class: "h-12 mt-2 ml-2 w-28 text-white",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hideCalendario()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Cancelar ")
              ]),
              _: 1
            }),
            _createVNode(_component_Button, {
              color: "custom",
              class: "h-12 2 ml-3 mt-2 w-28 text-white",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('consultarPeriodo')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Ok ")
              ]),
              _: 1
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}